import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Validate the candidate number.
 * A valid candidate number is made up of digits.
 * Alternatively, a candidate number may be made up of three letters and four digits.
 * If neither pattern fits, then an error is returned.
 *
 * @param {number}	length	The length of the candidate number.
 *
 * @return {bool}	A boolean indicating whether the candidate number is valid.
 */
export function candidateIDValidator(length: number): ValidatorFn {
	let patterns = [
		new RegExp(`^[0-9]{${length}}$`),
		new RegExp(`^[a-zA-Z]{3}[0-9]{4,5}$`),
	];
	return (control: AbstractControl): {[key: string]: any} | null => {
		if (! control.value) { return null }
		let valid_control = false;
		patterns.forEach((pattern) => {
			let valid = pattern.test(control.value);
			if (valid) {
				valid_control = true;
			}
		})
		return valid_control ? null : { "invalidCandidateID": {value: control.value} };
	};
}

export class Candidate {

	/**
	 * The candidate information is inputted in the candidate details page.
	 */

	constructor(
		public candidateID: string = "",
		public dateOfBirth: Date = new Date(0),
		public age: number = 0,
		public gender: string = "",
		public ethnicity: string = "Not Specified",
		public town: string = "0",
		public pathwayID: string = "",
	) { }

	/**
	 * Calculate the age using the year of birth.
	 * The age is calculated based only on years, and is thus rounded down.
	 *
	 * @param {Date}	birthday	The date of birth of the candidate.
	 * @return {number} The candidate's age.
	 */
	static calculateAge(birthday) {
		/*
		 * If the candidate's birthday has passed, the year can be subtracted from today's date.
		 * Otherwise, this difference is decremented.
		 */
		let years = (new Date()).getFullYear() - birthday.getFullYear();
		years = this.birthdayPassed(birthday) ? years : years - 1;

		/*
		 * The fractional part is calculated by comparing the days that have passed with the number of days that remain until the next birthday.
		 * This is because of leap years.
		 */
		let daysLeft = this.daysToNextBirthday(birthday);
		let daysSince = this.daysSinceLastBirthday(birthday);

		let age = years + (daysSince / (daysSince + daysLeft));
		return age;
	}

	/**
	 * Check whether the candidate has celebrated their birthday this year.
	 * Their birthday is said to have passed if today's month and day are greater than or equal their birthday.
	 *
	 * @param {Date}	birthday	The date of birth of the candidate.
	 *
	 * @return {boolean} A boolean indicating whether the candidate's birthday has passed.
	 */
	static birthdayPassed(birthday) {
		let today = new Date();
		return (today.getMonth() >= birthday.getMonth() &&
				today.getDate() >= birthday.getDate())
	}

	/**
	 * Get the number of days until the next birthday.
	 * The calculation is based on the timestamps to include leap years.
	 *
	 * @param {Date}	birthday	The date of birth of the candidate.
	 *
	 * @return {number} The number of days until the next birthday.
	 */
	static daysToNextBirthday(birthday) {
		/*
		 * If the birthday has already passed, the next birthday is next year.
		 * This logic is used to represent the full date of the next birthday.
		 */
		let yearOfBirthday = (new Date()).getFullYear();
		yearOfBirthday = this.birthdayPassed(birthday) ? yearOfBirthday + 1 : yearOfBirthday;
		let nextBirthday = new Date(yearOfBirthday, birthday.getMonth(), birthday.getDate());

		/*
		 * Represent today without time information.
		 */
		let today = new Date();
		let simplifiedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

		/*
		 * Get the difference in milliseconds and extract the number of days from it.
		 * One day is subtracted since today doesn't count towards days left.
		 */
		let ms = nextBirthday.getTime() - simplifiedToday.getTime()
		return ms / (60 * 60 * 24 * 1000);
	}

	/**
	 * Get the number of days until the next birthday.
	 * The calculation is based on the timestamps to include leap years.
	 *
	 * @param {Date}	birthday	The date of birth of the candidate.
	 *
	 * @return {number} The number of days until the next birthday.
	 */
	static daysSinceLastBirthday(birthday) {
		/*
		 * If the birthday has not already passed, the last birthday was last year.
		 * This logic is used to represent the full date of the last birthday.
		 */
		let yearOfBirthday = (new Date()).getFullYear();
		yearOfBirthday = this.birthdayPassed(birthday) ? yearOfBirthday : yearOfBirthday - 1;
		let lastBirthday = new Date(yearOfBirthday, birthday.getMonth(), birthday.getDate());

		/*
		 * Represent today without time information.
		 */
		let today = new Date();
		let simplifiedToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());

		/*
		 * Get the difference in milliseconds and extract the number of days from it.
		 */
		let ms = lastBirthday.getTime() - simplifiedToday.getTime()
		return Math.abs(ms / (60 * 60 * 24 * 1000));
	}

}
