import { Injectable } from '@angular/core';

/**
 * The modal service.
 *
 * From: https://jasonwatmore.com/post/2019/04/16/angular-7-custom-modal-window-dialog-box
 */
@Injectable({
	providedIn: 'root'
})
export class ModalService {

	/**
	 * The list of registered modals.
	 *
	 * @access public
	 * @type {any[]} modals
	 */
	public modals: any[] = [];

	constructor() { }

	/**
	 * Add a modal to the service.
	 *
	 * @param {any}	modal	The modal to register.
	 */
	add(modal: any) {
		this.modals.push(modal);
	}

	/**
	 * Remove a model from the service.
	 *
	 * @param {any}	modal	The modal to de-register.
	 */
	remove(modal: any) {
		this.modals = this.modals.filter(x => x.id !== modal.id);
	}

	/**
	 * Open the modal having the given ID.
	 *
	 * @param {string}	id	The ID of the modal to open.
	 */
	open(id: string) {
		const modal = this.modals.filter(x => x.id === id)[0];
		modal.open();
	}

	/**
	 * Close the modal having the given ID.
	 *
	 * @param {string}	id	The ID of the modal to close.
	 */
	close(id: string) {
		const modal = this.modals.filter(x => x.id === id)[0];
		modal.close();
	}

}
