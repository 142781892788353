import { Component, OnInit } from '@angular/core';

import { CandidateSelectionComponent, SelectionType } from '../candidate-selection/candidate-selection.component';

@Component({
	selector: 'app-mdh-candidate-selection',
	templateUrl: '../candidate-selection/candidate-selection.component.html',
	styleUrls: ['../candidate-selection/candidate-selection.component.css']
})
export class MDHCandidateSelectionComponent extends CandidateSelectionComponent implements OnInit {

	/**
     * The type of the selection screen.
     *
     * @type	{SelectionType}	type
     */
	public type: SelectionType = SelectionType.MDH;

	/**
	 * When the component loads, set the redirect URL manually.
	 */
	ngOnInit() {
		super.ngOnInit();
		this.redirectURL = 'medicalHistory';
	}

	/**
     * A function used to validate the request.
     * If the function validates, it should redirect.
     * Otherwise, it should show an error.
     *
     * @param {Event} event	The button click event.
     */
	validate(event: Event) {
		let candidateID = this.form.get('candidateID').value;
		this.apiService.getCandidateDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				this.router.navigate([this.redirectURL, response.candidateID, {candidatePathwayID: response.pathwayID}]);
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "Could not connect with server";
			}
		});
	}

}
