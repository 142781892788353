import { Component, OnInit } from '@angular/core';

import { CandidateSelectionComponent, SelectionType } from '../candidate-selection/candidate-selection.component';

@Component({
	selector: 'app-endoscopy-candidate-selection',
	templateUrl: '../candidate-selection/candidate-selection.component.html',
	styleUrls: ['../candidate-selection/candidate-selection.component.css']
})
export class EndoscopyCandidateSelectionComponent extends CandidateSelectionComponent implements OnInit {

	/**
     * The type of the selection screen.
     *
     * @type	{SelectionType}	type
     */
	public type: SelectionType = SelectionType.ENDOSCOPY;

	/*
		Denotes which tests are allows 0 for none, 1 for biopsy, 2 for mucosal, 3 for both
	*/
	public endoscopyTestMode: number = -1;

	/**
	 * When the component loads, set the redirect URL manually.
	 */
	ngOnInit() {
		super.ngOnInit();
		this.redirectURL = 'endoscopy';
	}

	/**
     * A function used to validate the request.
     * If the function validates, it should redirect.
     * Otherwise, it should show an error.
     *
     * @param {Event} event	The button click event.
     */
	validate(event: Event) {
		let candidateID = this.form.get('candidateID').value;
		this.apiService.getCandidateDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let cid = response.candidateID; // use the one in the response as this is converted to uppercase etc.
				this.apiService.canFollowPathway(cid, 'biopsy').subscribe((response_first_check) => {
					if (response_first_check.result == "ok") {

						this.apiService.canFollowPathway(cid, 'mucosaldeposits').subscribe((response_second_check) => {
							if (response_second_check.result == "ok") {
								// set the mode ... 
								if (response_first_check.check && response_second_check.check) {
									this.endoscopyTestMode = 3; // both
								} else if (!response_first_check.check && response_second_check.check) {
									this.endoscopyTestMode = 2; // mucosal only
								} else if (response_first_check.check && !response_second_check.check) {
									this.endoscopyTestMode = 1 //  biopsy only
								} else {
									this.endoscopyTestMode = 0 // none, show error
								}
								console.log(`Endoscopy tests mode is ${this.endoscopyTestMode}`);
								if (this.endoscopyTestMode > 0) {
									this.router.navigate([this.redirectURL, cid], { queryParams: { mode: this.endoscopyTestMode }});
								} else {
									this.error = 'wrong_pathway';
								}

							} else if (response_second_check.result == "error") {
								this.error = response_second_check.code;
							} else {
								this.error = "Could not connect with server";
							}
						});

					} else if (response_first_check.result == "error") {
						this.error = response_first_check.code;
					} else {
						this.error = "Could not connect with server";
					}
				});

			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "Could not connect with server";
			}
		});
	}

}
