import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	title = 'itama';

	/**
	 * On initialization, start listening for redirections.
	 * If the session does not have a token or an operator ID, redirect to the login page.
	 *
	 * @param {ActivatedRoute}	route		The currently-loaded route.
	 * @param {Router}			router		The router service.
	 */
	constructor (
		private router: Router,
		private route: ActivatedRoute
	) {
		this.router.events.subscribe((event: any): void => {
			if (event instanceof NavigationStart) {
				const required = [ 'token', 'operatorID' ]; // the properties to look for
				const rule = (prop) => localStorage.getItem(prop) ? false : true; // none of the required properties may be left out
				const no_auth = [ '/', '/resetPassword', '/resetPass' ];
				/*
				 * If any required property is not set and the page is not the login page, redirect back to the login page.
				 */
				if (required.some(rule) && ! this.requiresAuth(event.url, no_auth)) {
					router.navigate(['']);
				}
			}
		});
	}

	/**
	 * Check whether the router requires authentication.
	 * The route does not require authentication if it is the root, or if it starts with any path that is exempt of authentication.
	 *
	 * @param {string}		url 	The URL that requires validation.
	 * @param {string[]}	exempt	An array of routes that do not require authentication.
	 * @return {bool}		A boolean indicating whether the route requires authentication.
	 */
	requiresAuth(url, exempt) {
		return exempt.filter((route) => {
			return (route == '/' && url == route) || (route != '/' && url.indexOf(route) == 0);
		}).length > 0;
	}

}
