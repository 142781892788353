import { Component, OnInit,
 		 Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import { ApiService } from '../services/api.service';
import { Candidate,
		 candidateIDValidator,
} from "../classes/candidate"
import { FormComponent } from '../form/form.component';

@Component({
	selector: 'app-candidate-details',
	templateUrl: './candidate-details.component.html',
	styleUrls: [
		'./candidate-details.component.css',
	],
})
export class CandidateDetailsComponent extends FormComponent implements OnInit {

	/**
	 * Controls the Candidate Details page.
	 *
	 * The Candidate Details page takes the inputs, processes them and saves them into the candidate.
	 */

	/**
	 * The candidate whose details are being inputted.
	 *
	 * @access private
	 *
	 * @type {Candidate} candidate
	 */
	private model: Candidate = new Candidate();

	/**
	 * An edit flag to check whether the form signifies the creation of a new candidate or an update to an existing one.
	 *
	 * @access private
	 *
	 * @type {boolean} edit
	 */
	private edit: boolean = false;

	/**
	 * A list of towns from which the user can pick.
	 *
	 * @access public
	 **
	 * @type {string[]} towns
	 */
	public towns: { [key: string]: any }[] = [];

	/**
	 * A list of diagnostic pathways from which the user can pick.
	 *
	 * @access public
	 **
	 * @type {string[]} pathways
	 */
	public pathways: { [key: string]: any }[] = [];

	/**
	 * On initialization, create the form.
	 *
	 * @param {ApiService}		apiService		The service that connects to the API.
	 * @param {FormBuilder}		formBuilder		The form builder service.
	 * @param {Router}			router			The router service.
	 */
	constructor(
		apiService: ApiService,
		private formBuilder: FormBuilder,
		private router: Router,
		@Inject(LOCALE_ID) public locale: string
	) {
		/*
		 * From: https://github.com/angular/angular/issues/13831#issuecomment-319634921
		 *
		 * First override the route reuse strategy.
		 * Then reset the navigation flag and scroll to the top.
		 */
		super(apiService);
		this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; }
		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				this.router.navigated = false;
				window.scrollTo(0, 0);
			}
		});
	}

	/**
	 * When the component loads, change the date field into a date picker.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		this.loadTowns(localStorage.getItem('candidate-location'));
		this.loadPathways();
		super.ngOnInit();

		(<any> $("#dateOfBirth")).datepicker( {
			format: "dd/mm/yyyy",
			viewMode: "years",
			minViewMode: "days"
		});

		// NOTE: Listens to the `changeDate` event, a custom event triggered by the date picker.
		(<any> $("#dateOfBirth")).on("change", ((event) => {
			const input = event.currentTarget.value;
			const date_components = input.split('/');
			let date = new Date(parseInt(date_components[2]), parseInt(date_components[1]) - 1, parseInt(date_components[0]));
			if (input) {
				this.updateDateOfBirth(date);
			}
		}));
		(<any> $("#dateOfBirth")).on("changeDate", ((event) => {
			let date = event.date ? event.date : event.originalEvent.detail.date
			this.updateDateOfBirth(date);
		}));
	}

	/**
	 * Create a form group for the candidate model.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({
			candidateID: ["", [
				Validators.required,
				candidateIDValidator(5)
			]],
			pathwayID: [this.model.pathwayID, [
				Validators.required,
			]],
			dateOfBirth: ['', Validators.required],
			age: [0, [
				Validators.required,
				Validators.min(0),
			]],
			gender: [this.model.gender, Validators.required],
			ethnicity: [this.model.ethnicity, Validators.required],
			otherEthnicity: [],
			location: [localStorage.getItem('candidate-location') || 'MLT'],
			town: [this.model.town, Validators.required],
		});
	}

	/**
	 * Load the towns into the form.
	 *
	 * @param {string}	location	The location for which the towns should be loaded.
	 */
	loadTowns(location: string = null) {
		location = location == null ? location = this.form.get('location').value : location;
		localStorage.setItem('candidate-location', location);
		if (location != null) {
			this.apiService.getTowns(location).subscribe((response) => {
				if (response.result == "ok") {
					this.towns = response.towns;
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					this.error = "s001";
				}
			});
		}
	}

	/**
	 * Load the pathways into the form.
	 */
	loadPathways() {
		this.apiService.getPathways().subscribe((response) => {
			if (response.result == "ok") {
				this.pathways = response.diagnosticpathways;
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * Load the candidate details of the candidate having the currently-inputted ID.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	editCandidate(event) {
		const result = Object.assign({}, this.form.value);
		this.apiService.getCandidateDetails(result.candidateID).subscribe((response) => {
			if (response.result == "ok") {
				this.loadTowns(response.candidateTown.country);
				this.form.patchValue({
					location: response.candidateTown.country,
					town: response.candidateTown.id,
					ethnicity: response.candidateEthnicity,
					gender: response.candidateGender,
					pathwayID: response.pathwayID,
				});

				/*
				 * If the ethnicity is not one of the default values, update it.
				 * The form's ethnicity should be 'other'.
				 * The actual value should be loaded into the textbox.
				 */
				if (! ['Not Specified', 'Caucasian', 'African', 'Asian'].includes(response.candidateEthnicity)) {
					this.form.patchValue({
						ethnicity: 'Other',
						otherEthnicity: response.candidateEthnicity,
					})
				}

				/*
				 * Update the date of birth and recalculate the age.
				 */
				const date = response.candidateBirthdate.split('-');
				let dob = `${date[2].padStart(2, "0")}/${(parseInt(date[1])).toString().padStart(2, "0")}/${date[0].padStart(2, "0")}`; // months start from index 0
				(<any> $("#dateOfBirth")).datepicker("update", dob).val(dob);
				this.updateDateOfBirth($("#dateOfBirth").data().datepicker.date);

				/*
				 * Finally, mark the form as edit mode.
				 */
				this.edit = true;
			} else {
				this.form.get('candidateID').setErrors({ 'candidateDoesNotExist': true });
			}
		});
	}

	/**
	 * When the candidate number changes, disable the edit mode.
	 * Instead, consider the user to be creating a new candidate.
	 */
	candidateNumberOnChange() {
		this.edit = false;
		this.form.get('candidateID').setErrors({ 'candidateDoesNotExist': null });
		this.form.get('candidateID').updateValueAndValidity();
	}

	/**
	 * Get the form's values and send them to the API.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	onSubmit(event) {
		super.onSubmit(event);
		if (this.form.get('candidateID').hasError('candidateDoesNotExist')) {
			this.form.get('candidateID').setErrors({ 'candidateDoesNotExist': null });
			this.form.get('candidateID').updateValueAndValidity();
		}
		if (! this.form.valid) { return }

		const result = Object.assign({}, this.form.value);
		this.model = Object.assign(this.model, this.form.value);
		this.model.dateOfBirth = jQuery("#dateOfBirth").data('datepicker').date;

		/*
		 * If the other ethnicity has been chosen and filled in, make it the ethnicity.
		 */
		if (result.ethnicity == 'Other' && result.otherEthnicity) {
			this.model.ethnicity = result.otherEthnicity;
		}

		this.apiService.candidate(this.model, this.edit).subscribe((response) => {
			if (response.result == "ok") {
				jQuery('.success').removeClass('d-none');
				setTimeout(() => {
					/*
					 * Redirect based on which button was clicked.
					 */
					if ($(event.target).attr("id") == "submit-close") {
						this.router.navigate(['dataEntry']);
					} else {
						this.router.navigate(['details']);
					}
				}, 3000);
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * When the year of birth changes, update the candidate data as well.
	 * This action also triggers a change to the age.
	 *
	 * Note that the function is set up to listen to `changeDate` events.
	 * This is a custom event triggered by the date picker.
	 *
	 * @param {Date}	date		The new date.
	 */
	updateDateOfBirth(date) {
		let age = Candidate.calculateAge(date);
		this.form.controls["dateOfBirth"].setErrors(null);
		this.form.controls["age"].setValue(age);
	}

	get diagnostic() {
		return JSON.stringify(this.model);
	}

}
