import { Component, OnInit } from '@angular/core';

import { CandidateSelectionComponent, SelectionType } from '../candidate-selection/candidate-selection.component';

@Component({
	selector: 'app-blood-test-candidate-selection',
	templateUrl: '../candidate-selection/candidate-selection.component.html',
    styleUrls: ['../candidate-selection/candidate-selection.component.css']
})
export class BloodTestCandidateSelectionComponent extends CandidateSelectionComponent implements OnInit {

	/**
     * The type of the selection screen.
     *
     * @type	{SelectionType}	type
     */
    public type: SelectionType = SelectionType.BLOOD;

	/**
	 * When the component loads, set the redirect URL manually.
	 */
	ngOnInit() {
		super.ngOnInit();
		this.redirectURL = 'blood';
	}

	/**
     * A function used to validate the request.
     * If the function validates, it should redirect.
     * Otherwise, it should show an error.
     *
     * @param {Event} event	The button click event.
     */
	validate(event: Event) {
		let candidateID = this.form.get('candidateID').value;
		this.apiService.canFollowPathway(candidateID, 'bloodtests').subscribe((response) => {
			if (response.result == "ok") {
				if (! response.check) {
					this.error = 'wrong_pathway';
				} else {
					this.router.navigate([this.redirectURL, candidateID]);
				}
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

}
