import { Component, OnInit } from '@angular/core';

import { ApiService, LoginResponse, OperatorsResponse } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { User } from '../classes/user';
import { Router } from '@angular/router';

import { FormComponent } from '../form/form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent extends FormComponent implements OnInit {

  loginResponse: LoginResponse;

  model = new User('', ''); // empty

  submitted = false;

  constructor(apiService: ApiService,
              private authService: AuthService,
    		  public router: Router) {
		super(apiService);
	}

  ngOnInit() {
  }

  btnLogin() {
    this.apiService.login(this.model).subscribe((data: LoginResponse) => {
      this.loginResponse = {
        ...data
      }

      /*
       * If the user provided the correct credentials, redirect them to the menu.
       *
       * The token needs to be saved to local storage.
       * When the user reloads the page, the service loses its state, and thus, the token.
       * Instead, the token is saved into the session.
       * NOTE: Unset the token when the user logs out.
       */
      if (this.loginResponse.result == "ok") {
        this.authService.login(this.loginResponse.token, this.loginResponse.exp, this.loginResponse.operatorID);
        this.apiService.getOperator().subscribe((data: OperatorsResponse) => {
            if (data.result == "ok") {
                let operator = data.operators[0];
                this.authService.login(this.loginResponse.token, this.loginResponse.exp,
                    this.loginResponse.operatorID, operator.opRole, operator.opUsername);
                this.router.navigate(['menu']);
            }
        });

      } else if (this.loginResponse.result == "error") {
        this.error = this.loginResponse.code;
      } else {
        this.error = "s001";
      }
    });
  }

  onSubmit() { this.submitted = true; }

  // TODO: Remove this when we're done
  get diagnostic() { return JSON.stringify(this.model); }
}
