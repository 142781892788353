import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

import { ApiService } from '../services/api.service';

@Component({
	selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

	/**
	 * The received error code from the REST API.
	 *
	 * @access public
	 *
	 * @type {string} error
	 */
	public error: string = null;
	public errorMessage: string = null;

	/**
	 * The form with which the user interacts.
	 *
	 * @type {FormGroup} form
	 */
	form: FormGroup;

	constructor(protected apiService: ApiService) {}

	/**
	 * Initialize the form component.
	 */
	ngOnInit() {

	}

	/**
	 * When a form is submitted, touch all form controls.
	 * This ensures that any errors are shown.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	onSubmit(event) {
		(<any>Object).values(this.form.controls).forEach((control) => {
			control.markAsTouched();

			if (control instanceof FormArray) {
				control.controls.forEach((control) => {
					control.markAsTouched();
				})
			}
		});
	}

}
