import { Component, OnInit, Inject, LOCALE_ID, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { FormComponent } from '../form/form.component';

import { ApiService, Operator } from '../services/api.service';
import { CandidateStatusResponse } from '../services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../services/modal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Questionnaire } from '../classes/questionnaire';
import { Observable, Subject } from 'rxjs';

export interface BloodTest {
	id: string;
	dateExam: string;
	testBrand: string;
	testType: string;
	methodology: string;
	location?: string;
}

export interface AAC extends BloodTest {
	aac: number;
}

export interface DPGIGG extends BloodTest {
	dpgigg: number;
}

export interface DPGIGA extends BloodTest {
	dpgiga: number;
}

export interface IGATOT extends BloodTest {
	igatot: number;
}

export interface TTGIGA extends BloodTest {
	ttgiga: number;
	nTimes: number;
}

export interface TTGIGG extends BloodTest {
	ttgigg: number;
}

export interface EMA extends BloodTest {
	eval: string;
	photo: string;
	magnification: string;
	cameraBrand: string;
	cameraPxspc: string;
	cameraFocal: string;
	microBrand: string;
	microMagnification: string;
	slideBrand: string;
	slideWells: string;
	liquidBrand: string;
	liquidType: string;
	tissue: string;
}

export interface Biopsy {
	id: number;
	marsh: string;
	dateExam: string;
	sampleNumber: number;
	location?: string;
}

export interface Mucosal {
	id: number;
	eval: string;
	dateExam: string;
	photo: string;
	location?: string;
}

export interface POCTestJudgement {
	result: string;
	opName: string;
	opSurname: string;
	timestamp: string;
}

@Pipe({ name: "row" })
export class RowPipe implements PipeTransform {
	// input is an array of any
	// mod is the modulo, every mod items, create a row
	transform(input: any[], mod: number): any[][] {
		return input.reduce((previous, next, index) => {
			if (index % mod === 0) {
				previous.push([next]);
			} else {
				previous[previous.length - 1].push(next);
			}
			return previous;
		}, <any[][]>[]);
	}
}

@Component({
	selector: 'app-diagnostic-pathway',
	templateUrl: './diagnostic-pathway.component.html',
	styleUrls: ['./diagnostic-pathway.component.css']
})
export class DiagnosticPathwayComponent extends FormComponent implements OnInit {

	candidateID: string = "";
	private questionsOnly: { [qid: string]: string; } = {};

	// candidate details
	candidateCodfis: string;
	candidateBirthdate: string;
	candidateGender: string;
	candidateEthnicity: string;
	candidateCountry: string;
	candidateTown: string;


	// POC
	poctPhoto1: string = '';
	poctPhoto2: string = '';
	hasPOCT: boolean = false;

	// MHQ
	hasMHQ: boolean = false;
	// status
	bloodsEnabled: boolean = false;
	endoscopyEnabled: boolean = false;
	// has one blood
	hasOneBlood: boolean = false;
	hasOneEndo: boolean = false;

	blood2EndoOp: Operator = null;
	quest2BloodOp: Operator = null; 
	diagnoserOp: Operator = null;

	mdDate: string;
	mdRelatives: string;
	mdLocation: string;
	private mdQuestionsAndAnswers: [string, string][] = [];

	// aac blood tests
	aacBloodTests: AAC[] = [];

	// dpgigg
	dpgiggBloodTests: DPGIGG[] = [];

	// dpgiga
	dpgigaBloodTests: DPGIGA[] = [];

	// ema
	emaBloodTests: EMA[] = [];

	// igatot
	igatotBloodTests: IGATOT[] = [];

	// ttgiga
	ttgigaBloodTests: TTGIGA[] = [];

	// ttgigg
	ttgiggBloodTests: TTGIGG[] = [];

	// biopsy
	biopsyTests: Biopsy[] = [];

	// mucosal deposits
	mucosalTests: Mucosal[] = [];

	// POC Test Judgement
	pocTestJudgements: POCTestJudgement[];

	// the final diagnosis
	finalDiagnosis: string = "None";

	// pathways
	public pathways: { [key: string]: any }[] = [];
	public pathwaysDescription: { [id: string]: string } = {};
	public pathwayID: string;
	public pathwayDesc: string;

	constructor(apiService: ApiService,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		public modalService: ModalService,
		private cdr: ChangeDetectorRef,
		@Inject(LOCALE_ID) public locale: string) {
		super(apiService);
	}

	ngOnInit() {
		super.ngOnInit();

		this.questionsOnly = this.constructQuestionsDict();

		this.route.params.subscribe(params => {
			this.candidateID = params.candidateID ? params.candidateID : '';
			this.loadCandidateStatus(this.candidateID)
			this.loadCandidate(this.candidateID);
			this.loadPOC(this.candidateID);
			this.loadPOCJudgements(this.candidateID);
			this.loadMedicalHistory(this.candidateID);
			this.loadBloodTests(this.candidateID);
			this.loadEndoscopy(this.candidateID);
			this.loadFinalDiagnostic(this.candidateID);
		});

	}

	public showBloodSection(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.hasMHQ && this.hasPOCT);
		}
		return true;
	}

	public showEndoscopySection(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.hasMHQ && this.hasPOCT);
		}
		return true;
	}

	public showDiagnosisSection(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.hasPOCT && this.hasMHQ);
		} else if (this.pathwayID == 'ita-adultsendo') {
			return this.getEndoscopyTestsNum() > 0; // must have at least one endo to show
		} else {
			return true;
		}
	}

	public showDiagnosis(): boolean {
		return (!!this.finalDiagnosis) && this.finalDiagnosis != 'None';
	}

	public showBloodTests(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.bloodsEnabled && this.getBloodTestsNum() > 0);
		}
		return this.getBloodTestsNum() > 0;
	}

	public showEndoscopyTests(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.endoscopyEnabled && this.getEndoscopyTestsNum() > 0);
		}
		return this.getEndoscopyTestsNum() > 0;
	}


	public showBloodsNotEnabledMsg(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.hasPOCT && this.hasMHQ && !this.bloodsEnabled);
		}
		return false;
	}

	public showEndoscopyNotEnabledMsg(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return !this.endoscopyEnabled;
		}
		return false; // add pathway info in here
	}

	public showEnableEndoscopyButton(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.bloodsEnabled && !this.endoscopyEnabled && this.finalDiagnosis == "None" && this.hasOneBlood);
		}
		return false;
	}


	public showEnableBloodsButton(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (!this.bloodsEnabled && this.finalDiagnosis == "None");
		}
		return false;
	}


	public showNoEndoscopyTestsMsg(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.endoscopyEnabled && this.getEndoscopyTestsNum() == 0);
		}
		return this.getEndoscopyTestsNum() == 0;
	}


	public showNoBloodTestsMsg(): boolean {
		if ((this.pathwayID == 'mlt-screening') || (this.pathwayID == 'ita-kidsparents')) {
			return (this.bloodsEnabled && this.getBloodTestsNum() == 0);
		}
		return (this.getBloodTestsNum() == 0);
	}

	public loadCandidateStatus(candidateID: string): void {
		this.apiService.getCandidateStatus(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let c: CandidateStatusResponse = response;
//console.log(c);
				this.hasMHQ = (c.hasMHQ == 'Y');
				this.hasPOCT = (c.hasPoCT == 'Y');
				this.bloodsEnabled = (c.enabledForBlood == 'Y');
				this.endoscopyEnabled = (c.enabledForEndo == 'Y');
				this.hasOneBlood = (c.hasOneBlood == 'Y');
				this.hasOneEndo = (c.hasOneEndo== 'Y');
				this.finalDiagnosis = c.diagnosis;
				this.diagnoserOp = c.diagnoser;
				this.quest2BloodOp = c.quest2Blood;
				this.blood2EndoOp = c.blood2Endo;
				this.cdr.detectChanges(); // buttons not always showing based on the above, force DOM update
			} else if (response.result == "error") {
				console.log(`Error (${response.message}) when checking for candidate status for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});
	}


	public getBloodTestsNum(): number {
		return (this.aacBloodTests.length +
			this.dpgiggBloodTests.length +
			this.dpgigaBloodTests.length +
			this.emaBloodTests.length +
			this.igatotBloodTests.length +
			this.ttgigaBloodTests.length +
			this.ttgiggBloodTests.length);
	}

	public getEndoscopyTestsNum(): number {
		return (this.biopsyTests.length + this.mucosalTests.length);
	}

	public loadFinalDiagnostic(candidateID: string): void {
		this.apiService.checkFinalDiagnosis(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				this.finalDiagnosis = response.Diagnosis;
			} else if (response.result == "error") {
				console.log(`Probably, no diagnosis for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});
	}

	orderBloodTests($e) {
		this.apiService.proceedToNextTests(this.candidateID).subscribe(response => {
			if (response.result == "ok") {
				console.log(`Candidate ${this.candidateID} moved to next tests`);
				this.resetCandidateStatus();
			} else {
				console.log(`An error occured (code: ${response.code}).  Unable to move candidate ${this.candidateID} to next step.  Error Message: ${response.message}`);
			}
		});
	}

	orderEndoscopyTests($e) {
		this.apiService.proceedToNextTests(this.candidateID).subscribe(response => {
			if (response.result == "ok") {
				console.log(`Candidate ${this.candidateID} moved to next tests`);
				this.resetCandidateStatus();
			} else {
				console.log(`An error occured (code: ${response.code}).  Unable to move candidate ${this.candidateID} to next step.  Error Message: ${response.message}`);
			}
		});
	}


	private constructQuestionsDict(): { [qid: string]: string; } {
		let ques = {};
		switch (this.locale) {
			case "it":
				Questionnaire.ITALIAN_QUESTIONS.forEach(function (v) {
					ques[v.paramName] = v.questionText;
				});
			case "en-US":
			case "en-GB":
			default:
				let allQ = [...Questionnaire.MALTESE_QUESTIONS_KIDS, ...Questionnaire.MALTESE_QUESTIONS_ADULTS];
				allQ.forEach(function (v) {
					ques[v.paramName] = v.questionText;
				});
		}
		return ques;
	}

	loadCandidate(candidateID: string) {
		this.apiService.getCandidateDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				this.candidateCodfis = response.candidateCodfis;
				this.candidateBirthdate = response.candidateBirthdate;
				this.candidateGender = response.candidateGender;
				this.candidateEthnicity = response.candidateEthnicity;
				this.candidateTown = response.candidateTown.town;
				this.candidateCountry = response.candidateTown.country;
				this.readablePathway(response.pathwayID);


			} else if (response.result == "error") {
				console.log(`Probably, no candidate details for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});
	}

	/**
	 * Load the POC test images for the candidate with the given ID.
	 *
	 * @param {string}	candidateID	The ID of the candidate whose POC test images will be shown.
	 */
	loadPOC(candidateID: string): void {
		this.apiService.getPOCTestDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				response = this.apiService.constructPOCTestImageURLs(response);
				this.poctPhoto1 = response.poctPhoto1;
				this.poctPhoto2 = response.poctPhoto2;
			}
		});
	}

	loadPOCJudgements(candidateID: string): void {
		this.apiService.getCandidateJudgements(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				this.pocTestJudgements = []; // at worst set to empty
				if (candidateID in response.results) { // defensive programming in the case there are no judgements yet
					let judgements = response.results[candidateID].judgements;
					judgements.forEach(j => {

						const judgement: POCTestJudgement = {
							timestamp: j.timestamp,
							result: j.poctRes,
							opName: j.operator.opName,
							opSurname: j.operator.opSurname
						}
						this.pocTestJudgements.push(judgement);
					});
				}

			} else if (response.result == "error") {
				console.log(`Probably, no POC Judgements for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});
	}

	loadMedicalHistory(candidateID: string): void {
		this.apiService.getCandidateMedicalHistory(candidateID).subscribe((response) => {

			if (response.result == "ok") {
				let candidateQuestionnaire = response.questionnaires[candidateID];

				this.mdDate = candidateQuestionnaire.questDatefilledin;
				this.loadLocation(candidateQuestionnaire.questLocation).subscribe(l => this.mdLocation = l);
				this.mdQuestionsAndAnswers = []; // clear this array
				let questKeys = Object.keys(candidateQuestionnaire).filter(function (k) { return k.startsWith('quest'); }); // interesting questions
				questKeys.forEach((k) => {
					if (k in this.questionsOnly && candidateQuestionnaire[k]) {
						this.mdQuestionsAndAnswers.push([this.questionsOnly[k], candidateQuestionnaire[k]]);
					}
				});

				// this is a HACK ! to make the UI think this is a new array and for it to refresh the for loop
				this.mdQuestionsAndAnswers = this.mdQuestionsAndAnswers.slice(0,this.mdQuestionsAndAnswers.length);  // new array

				if (candidateQuestionnaire.parents.length == 1) {
					if (candidateQuestionnaire.parents[0] == "none") {
						this.mdRelatives = "No";
					} else if (candidateQuestionnaire.parents[0] == "N_A") {
						this.mdRelatives = "Unknown";
					} else {
						this.mdRelatives = `Yes (${candidateQuestionnaire.parents[0]})`;
					}
				} else {
					this.mdRelatives = `Yes (${candidateQuestionnaire.parents.join(', ')})`;;
				}						
	

			} else if (response.result == "error") {
				console.log(`Probably, no questionnaire for candidate ${candidateID} (code: ${response.code}, message:${response.message})`);
			} else {
				this.error = "Could not connect with server";
			}
		});
	}

	loadBloodTests(candidateID: string): void {

		// aac
		this.apiService.getBloodTest("aac", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.aacBloodTests = [];
				exams.forEach(exam => {

					const aac: AAC = {
						id: exam.aacID,
						aac: exam.aac,
						dateExam: exam.dateexam,
						testBrand: exam.testBrand,
						testType: exam.testType,
						methodology: exam.methodology,
					}
					this.loadLocation(exam.locationID).subscribe(l => aac.location = l);
					this.aacBloodTests.push(aac);

				});

			} else if (response.result == "error") {
				console.log(`Probably, no aac test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// dpgigg
		this.apiService.getBloodTest("dpgigg", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.dpgiggBloodTests = [];
				exams.forEach(exam => {

					const dpgigg: DPGIGG = {
						id: exam.dpgiggID,
						dpgigg: exam.dpg_igg,
						dateExam: exam.dateexam,
						testBrand: exam.testBrand,
						testType: exam.testType,
						methodology: exam.methodology,
					}
					this.loadLocation(exam.locationID).subscribe(l => dpgigg.location = l);
					this.dpgiggBloodTests.push(dpgigg);

				});

			} else if (response.result == "error") {
				console.log(`Probably, no dpgigg test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// dpgiga
		this.apiService.getBloodTest("dpgiga", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.dpgigaBloodTests = [];
				exams.forEach(exam => {

					const dpgiga: DPGIGA = {
						id: exam.dpgigaID,
						dpgiga: exam.dpg_iga,
						dateExam: exam.dateexam,
						testBrand: exam.testBrand,
						testType: exam.testType,
						methodology: exam.methodology,
					}
					this.loadLocation(exam.locationID).subscribe(l => dpgiga.location = l);
					this.dpgigaBloodTests.push(dpgiga);

				});

			} else if (response.result == "error") {
				console.log(`Probably, no dpgiga test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// ema
		this.apiService.getBloodTest("ema", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.emaBloodTests = [];
				exams.forEach(exam => {

					const ema: EMA = {
						id: exam.emaID,
						dateExam: exam.dateexam,
						methodology: exam.methodology,
						eval: exam.emaEval,
						photo: this.apiService.constructImageURL(exam.emaPhoto),
						magnification: exam.magnification,
						cameraBrand: exam.cameraBrand,
						cameraPxspc: exam.camerapxspc,
						cameraFocal: exam.cameraFocal,
						microBrand: exam.microBrand,
						microMagnification: exam.microMagnification,
						slideBrand: exam.slideBrand,
						slideWells: exam.slideWells,
						liquidBrand: exam.liquidBrand,
						liquidType: exam.liquidType,
						tissue: exam.tissue,
						testBrand: '',
						testType: '',
					}
					this.loadLocation(exam.locationID).subscribe(l => ema.location = l);
					this.emaBloodTests.push(ema);
				});

			} else if (response.result == "error") {
				console.log(`Probably, no ema test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// igatot
		this.apiService.getBloodTest("igatot", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.igatotBloodTests = [];
				exams.forEach(exam => {

					const igatot: IGATOT = {
						id: exam.igatotID,
						igatot: exam.iga_tot,
						dateExam: exam.dateexam,
						testBrand: exam.testBrand,
						testType: exam.testType,
						methodology: exam.methodology,
					}
					this.loadLocation(exam.locationID).subscribe(l => igatot.location = l);
					this.igatotBloodTests.push(igatot);
				});

			} else if (response.result == "error") {
				console.log(`Probably, no igatot test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// ttgiga
		this.apiService.getBloodTest("ttgiga", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.ttgigaBloodTests = [];
				exams.forEach(exam => {

					const ttgiga: TTGIGA = {
						id: exam.ttgigaID,
						ttgiga: exam.ttg_iga,
						nTimes: exam.nTimes,
						dateExam: exam.dateexam,
						testBrand: exam.testBrand,
						testType: exam.testType,
						methodology: exam.methodology,
					}
					this.loadLocation(exam.locationID).subscribe(l => ttgiga.location = l);
					this.ttgigaBloodTests.push(ttgiga);
				});

			} else if (response.result == "error") {
				console.log(`Probably, no ttgiga test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// ttgigg
		this.apiService.getBloodTest("ttgigg", candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response.exams[candidateID];
				this.ttgiggBloodTests = [];
				exams.forEach(exam => {

					const ttgigg: TTGIGG = {
						id: exam.ttgiggID,
						ttgigg: exam.ttg_igg,
						dateExam: exam.dateexam,
						testBrand: exam.testBrand,
						testType: exam.testType,
						methodology: exam.methodology,
					}
					this.loadLocation(exam.locationID).subscribe(l => ttgigg.location = l);
					this.ttgiggBloodTests.push(ttgigg);
				});
			} else if (response.result == "error") {
				console.log(`Probably, no ttgigg test for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

	}


	loadEndoscopy(candidateID: string): void {

		// biopsy
		this.apiService.getBiopsy(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response["exams"][candidateID];
				this.biopsyTests = [];
				exams.forEach(exam => {

					const biopsy: Biopsy = {
						id: exam.biopsyID,
						dateExam: exam.dateexam,
						marsh: exam.marsh,
						sampleNumber: exam.samplenr,
					}
					this.loadLocation(exam.locationID).subscribe(l => biopsy.location = l);
					this.biopsyTests.push(biopsy);
				});

			} else if (response.result == "error") {
				console.log(`Probably, no biopsy for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});

		// mucosal deposits
		this.apiService.getMucosalDeposits(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				let exams = response["exams"][candidateID];
				this.mucosalTests = [];
				exams.forEach(exam => {
					const mucosal: Mucosal = {
						id: exam.mucosalID,
						dateExam: exam.dateexam,
						eval: exam.mucosalEval,
						photo: this.apiService.constructImageURL(exam.mucosalPhoto),
					}
					this.loadLocation(exam.locationID).subscribe(l => mucosal.location = l);
					this.mucosalTests.push(mucosal);
				});

			} else if (response.result == "error") {
				console.log(`Probably, no mucosal deposits for candidate ${candidateID} (code: ${response.code})`);
			} else {
				this.error = "Could not connect with server";
			}
		});
	}


	loadLocation(locationID: string): Observable<string> {
		var subject = new Subject<string>();
		this.apiService.getLocations(null, null, locationID).subscribe(response => {
			let loc = response.locations[0].locName; // many assumptions here ... it worked, only one result etc
			subject.next(loc);
		});
		return subject.asObservable();
	}

	markAsNonCeliac($e) {
		this.apiService.markAsNonCeliac(this.candidateID).subscribe(response => {
			if (response.result == "ok") {
				console.log(`Candidate ${this.candidateID} marked as non celiac`);
				this.resetCandidateStatus();
			} else {
				console.log(`An error occured (code: ${response.code}).  Unable to mark candidate ${this.candidateID} as non celiac.  Error Message: ${response.message}`);
			}
		});
	}

	markAsCeliac($e) {
		this.apiService.markAsCeliac(this.candidateID).subscribe(response => {
			if (response.result == "ok") {
				console.log(`Candidate ${this.candidateID} marked as celiac`);
				this.resetCandidateStatus();
			} else {
				console.log(`An error occured (code: ${response.code}).  Unable to mark candidate ${this.candidateID} as celiac.  Error Message: ${response.message}`);
			}
		});
	}


	markAsRefusedFurtherTesting($e) {
		this.apiService.markAsRefusedFurtherTesting(this.candidateID).subscribe(response => {
			if (response.result == "ok") {
				console.log(`Candidate ${this.candidateID} marked as refused further testing`);
				this.resetCandidateStatus();
			} else {
				console.log(`An error occured (code: ${response.code}).  Unable to mark candidate ${this.candidateID} as refused further testing.  Error Message: ${response.message}`);
			}
		});
	}
	

	resetCandidateStatus() {
		this.loadCandidateStatus(this.candidateID);
	}


	public readablePathway(pathwayID: string): void {
		this.apiService.getPathways().subscribe(response => {
			if (response.result == "ok") {
				response.diagnosticpathways.forEach(p => {
					this.pathwaysDescription[p.pathwayID] = p.pwyDescr;
					if (pathwayID == p.pathwayID) {
						this.pathwayDesc = p.pwyDescr;
						this.pathwayID = p.pathwayID;
					}
				})
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

}
