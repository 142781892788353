import { Component, Input, OnInit } from '@angular/core';
import { ApiService, Relative, Question } from '../services/api.service';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { candidateIDValidator, Candidate } from '../classes/candidate';
import { FormComponent } from '../form/form.component';
import { QuestType } from '../classes/quest-type';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css']
})
export class MedicalHistoryComponent extends FormComponent implements OnInit {

  editMode: boolean = false;
  showRel: boolean = false;

  form: FormGroup; // the form holding the medical quest. info.

  questions: Question[];
  dbRelatives: Relative[];
  questionnaireDate: Date = new Date(Date.now());
  candidateID: string = "";
  location: string = "";
  candidatePathwayID: string = "";
  country: string = ""; // hard coded as I do not think we need this FIXME otherwise
  locations: { [key: string]: any }[] = [];

  /**
	 * A list of towns from which the user can pick.
	 *
	 * @access public
	 **
	 * @type {string[]} towns
	 */
  public towns: { [key: string]: any }[] = [];

  constructor(apiService: ApiService, private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {
    super(apiService);
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      candidateID: [0, [
        Validators.required,
        candidateIDValidator(5)
      ]],
      inputDate: ['', Validators.required],
      country: [this.country, Validators.required],
      location: [this.location, Validators.required],
      relatives: new FormArray([]),
      questions: new FormArray([]),
      relativesSel: ['none']
    });

    this.loadLocations();

    (<any>$("#inputDate")).datepicker({
      format: "dd/mm/yyyy",
      viewMode: "years",
      minViewMode: "days"
    });

    // NOTE: Listens to the `changeDate` event, a custom event triggered by the date picker.
    (<any>$("#inputDate")).on("change", ((event) => {
      const input = event.currentTarget.value;
      const date_components = input.split('/');
      let inputDate = new Date(parseInt(date_components[2]), parseInt(date_components[1]) - 1, parseInt(date_components[0]));
      this.form.controls["inputDate"].setErrors(null);
      const today = new Date();
      if (inputDate > today) {
        this.form.controls["inputDate"].setErrors({ 'date_in_future': true });
      } else {
        this.form.controls["inputDate"].setErrors(null);
      }
    }));
    (<any>$("#inputDate")).on("changeDate", ((event) => {
      let inputDate = event.date ? event.date : event.originalEvent.detail.date;
      const today = new Date();
      if (inputDate > today) {
        this.form.controls["inputDate"].setErrors({ 'date_in_future': true });
      } else {
        this.form.controls["inputDate"].setErrors(null);
      }
    }));

    this.route.params.subscribe(params => {
      this.candidateID = params.candidateID ? params.candidateID : '';

      if (this.candidateID) {
        this.form.controls["candidateID"].setValue(this.candidateID);
        this.loadMedicalHistory(this.candidateID) // will check if medical history already exists
      }
    });

    this.addRelativeCheckboxes(); // FIXME double creation, same below
    this.editMode = false;

  }

  private renderQuestions(candidateID: string, candidateQuestionnaire = null): void {
    this.apiService.getCandidateDetails(candidateID).subscribe((response) => {
      if (response.result == "ok") {
        var intcand = parseInt(response.candidateID);
        console.log(intcand);
        if (response.pathwayID == "mlt-screening"){
          if(response.candidateID.startsWith('CAP')) {
          // kids
          this.questions = this.apiService.getMedicalQuestionnaire(QuestType.ADULTS); 
          }
          else{
          this.questions = this.apiService.getMedicalQuestionnaire(QuestType.KIDS);
          } 
        } else {
          // adults
          this.questions = this.apiService.getMedicalQuestionnaire(QuestType.ADULTS);
        }

        if (candidateQuestionnaire) {
          this.questions.forEach(q => {
            // this should execute in case of edit mode
            let optionsControl = null;
            optionsControl = new FormControl(candidateQuestionnaire[q.paramName]);
            (this.form.controls.questions as FormArray).push(optionsControl);
          });
        } else {
          this.questions.forEach(q => {
            let optionsControl = null;
            optionsControl = new FormControl(response[q.paramName]);
            (this.form.controls.questions as FormArray).push(optionsControl);
          });
        }
      } else if (response.result == "error") {
        this.error = response.code;
      } else {
        this.error = "Could not connect with server";
      }
    });
  }

  private addRelativeCheckboxes(rel: string[] = []): void {
    this.dbRelatives = this.apiService.getRelativesWithDiseaseForMedicalQuestionnaire();
    if (rel) {
      this.form.controls.relatives = new FormArray([]);
      this.dbRelatives.forEach(r => {
        const control = new FormControl(rel.includes(r.value));
        (this.form.controls.relatives as FormArray).push(control);
      });
    } else {
      for (let r in this.dbRelatives) {
        const control = new FormControl(false);
        (this.form.controls.relatives as FormArray).push(control);
      }
    }
  }


  loadMedicalHistory(candidateID): void {
    this.editMode = true;
    this.apiService.getCandidateMedicalHistory(candidateID).subscribe((response) => {
      if (response.result == "ok") {
        let candidateQuestionnaire = response.questionnaires[candidateID];

        // set the country and location
        this.loadCountryAndLocation(candidateQuestionnaire.questLocation)

        // quest. input date stuff
        let inputDateDB = candidateQuestionnaire.questDatefilledin.split('-');
        let inputDate = `${inputDateDB[2].padStart(2, "0")}/${(parseInt(inputDateDB[1])).toString().padStart(2, "0")}/${inputDateDB[0].padStart(2, "0")}`; // months start from index 0
        (<any>$("#inputDate")).datepicker("update", inputDate).val(inputDate);
        this.form.controls["inputDate"].setErrors(null);

        // patch the correct value when loading the questionnaire
        if (candidateQuestionnaire.parents.indexOf('N_A') > -1) {
          this.form.patchValue({ 'relativesSel': 'u' })
        } else if (candidateQuestionnaire.parents.indexOf('none') > -1) {
          this.form.patchValue({ 'relativesSel': 'n' })
        } else {
          this.form.patchValue({ 'relativesSel': 'y' })
          this.addRelativeCheckboxes(candidateQuestionnaire.parents);
        }

        this.renderQuestions(this.candidateID, candidateQuestionnaire);
        this.error = '';
        this.editMode = true;

      } else if (response.result == "error") {
        console.log(`Probably, no questionnaire for candidate ${candidateID} (code: ${response.code})`);
        if (response.code == "0012_0004") { // not an error, just no existing questionnaire
          this.renderQuestions(this.candidateID);
        }
      } else {
        this.error = "Could not connect with server";
      }
    });
  }

  /**
   * Get the form's values and send them to the API.
   *
   * @param {MouseEvent}	event	The click event.
   */
  onSubmit(event) {
    super.onSubmit(event);

    if (!this.form.valid) {
      const invalid = [];
      const controls = this.form.controls;
      for (const name in controls) {
        if (controls[name].invalid && name == "questions") {
          this.error = "gui_001";
          this.errorMessage = ""; // the message is taken from the translations file
        }
      }
      return
    } else {
      // form valid -- clear errors
      this.error = "";
      this.errorMessage = "";
    }

    let rels = 'N_A';
    if (this.form.get('relativesSel').value == 'y') {
      rels = this.dbRelatives.filter((r, ind) => this.form.controls.relatives.value[ind]).map(r => r.value).join(",");
    } else if (this.form.get('relativesSel').value == 'n') {
      rels = "none";
    }
    let location = this.form.value.location;
    let candidateID = this.form.value.candidateID;
    let questionnaireDate = jQuery("#inputDate").data('datepicker').date;
    let questions: [string, string][] = [];
    this.form.value.questions.forEach((q, index) => {
      questions.push([this.questions[index].paramName, q]);
    });
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttrVal = target.attributes.id.value;
    let buttonId = idAttrVal.toLocaleUpperCase();
    if (buttonId.includes('NEXT')) {
      this.apiService.addCandidateMedicalHistory(candidateID, questionnaireDate, location, rels, questions).subscribe((response) => {
        if (response.result == "ok") {
          console.log("Saved medical history for candidateID " + candidateID);
          jQuery('.success').removeClass('d-none'); // show success message
          setTimeout(() => {
            this.router.navigate(['dataEntry']);
          }, 3000);
        } else if (response.result == "error") {
          this.error = response.code;
          this.errorMessage = response.message;
        } else {
          this.error = "s001";
        }
      });

    } else if (buttonId.includes('NEW')) {
      this.apiService.addCandidateMedicalHistory(candidateID, questionnaireDate, location, rels, questions).subscribe((response) => {
        if (response.result == "ok") {
          console.log("Saved medical history for candidateID " + candidateID);
          jQuery('.success').removeClass('d-none'); // show success message
          setTimeout(() => {
            this.router.navigate(['/candidateSelection/medicalHistory']);
          }, 3000);
        } else if (response.result == "error") {
          this.error = response.code;
          this.errorMessage = response.message;
        } else {
          this.error = "s001";
        }
      });

    } else if (buttonId.includes('UPDATE')) {
      this.apiService.addCandidateMedicalHistory(candidateID, questionnaireDate, location, rels, questions, true).subscribe((response) => { // really an edit
        if (response.result == "ok") {
          console.log("Updated medical history for candidateID " + candidateID);
          jQuery('.success').removeClass('d-none'); // show success message
          setTimeout(() => {
            this.router.navigate(['dataEntry']);
          }, 3000);
        } else if (response.result == "error") {
          this.error = response.code;
          this.errorMessage = response.message;
        } else {
          this.error = "s001";
        }
      });

    } else {
      throw new Error("Unable to parse button text ${buttonText}");
    }
  }

  /**
	 * Load the locations.
	 */
  loadLocations(country: string = null) {
    this.locations = [];
    if (country) {
      this.country = country;
    } else {
      this.country = this.form.value['country'];
      localStorage.setItem('country', this.country);
    }
    let locType = this.country == 'MLT' ? 'school' : 'hospital' // assume in Italy we want hospitals not schools
    this.apiService.getLocations(this.country, locType).subscribe((response) => {
      if (response.result == "ok") {
        this.locations = response.locations;
      } else if (response.result == "error") {
        this.error = response.code;
        this.errorMessage = response.message;
      } else {
        this.error = "s001";
      }
    });
  }

  /**
     * Load the locations.
     */
  loadCountryAndLocation(locationId: string) {
    this.apiService.getLocations(null, null, locationId).subscribe((response) => {
      if (response.result == "ok") {
        let loc = response.locations[0]; // only 1 should be returned since looking up with id ...
        this.form.patchValue({
          country: loc.locState,
          location: loc.locationID,
        });
        this.loadLocations(loc.locState);
      } else if (response.result == "error") {
        this.error = response.code;
        this.errorMessage = response.message;
      } else {
        this.error = "s001";
      }
    });
  }

  showRelatives(display: boolean) {
    this.showRel = display;
  }

	/**
	 * When the testing location changes, cache it.
	 */
  cacheLocation() {
    localStorage.setItem('location', this.form.value['location']);
  }

}
