import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
	/**
	 * A service that is used to check whether the user is authenticated.
	 */

	constructor() { }

    /**
     * Log in the user, setting the necessary information in the local storage.
     *
     * @param {string}  token       The user's access token.
     * @param {number}  expiry      The expiry length, in seconds, of the user's access token.
     * @param {string}  operatorID  The user's operator ID.
     * @param {string}  role        The user's role.
     * @param {string}  username    The user's username.
     */
    public login(token: string, expiry: string, operatorID: string,
                 role: string="", username: string=""): void {

        // calculate the token's expiry timestamp
        let expiry_ts = (new Date()).getTime() / 1000 + parseInt(expiry);

        localStorage.setItem("token", token);
        localStorage.setItem("expiry", expiry_ts.toString());
        localStorage.setItem("operatorID", operatorID);
        localStorage.setItem("role", role);
        localStorage.setItem("username", username);
    }

    /**
     * Log out the user, removing all their information from local storage.
     */
    public logout(): void {
        localStorage.removeItem("token");
        localStorage.removeItem("expiry");
        localStorage.removeItem("operatorID");
        localStorage.removeItem("role");
        localStorage.removeItem("username");
    }

	/**
	 * Check whether the user has an access token.
	 *
	 * @return {boolean} A boolean indicating whether the user has an access token.
	 */
	public hasToken(): boolean {
		let token = localStorage.getItem("token");
		return !! token; // reduce the token to a boolean
	}

    /**
	 * Check whether the token has expired.
	 *
	 * @return {boolean} A boolean indicating whether the user's access token has expired.
	 */
	public hasExpired(): boolean {
		let expiry = localStorage.getItem("expiry");
        return (new Date()).getTime() / 1000 >= parseInt(expiry);
	}

	/**
	 * Check whether the user has an operator ID.
	 * Without an operator ID, the user can neither fill in the medical histories nor judge POC tests.
	 *
	 * @return {boolean} A boolean indicating whether the user has an operator ID.
	 */
	public hasOperatorID(): boolean {
		let operatorID = localStorage.getItem("operatorID");
		return !! operatorID; // reduce the operatorID to a boolean
	}

    /**
     * Get the operator role of the currently-logged in user.
     *
     * @return {string} The operator role of the currently-logged in user.
     */
    public getRole(): string {
        return localStorage.getItem("role");
    }

}
