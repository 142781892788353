/**
 * A pipe that receives errors and retains only the first one.
 * This can be used to show a single error prompt where several are possible.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'firstError'
})
export class FirstErrorPipe implements PipeTransform {

	/**
	 * Transform the given object into a single value.
	 *
	 * @param {object} value	The value to transform.
	 * @param {object} args		Optional arguments for the transformation.
	 *
	 * @return {any} The first value if it exists, null otherwise.
	 */
	transform(value: any, args?: any): any {
		var keys = Object.keys(value);
		if (keys && keys.length > 0) {
			return keys[0];
		}
		return null;
	}

}
