import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from "./services/auth-guard.service";
import { CanDeactivateGuard } from "./services/can-deactivate.guard";

import { LoginComponent }      from './login/login.component';
import { ResetPasswordComponent }      from './reset-password/reset-password.component';
import { PocTestComponent }      from './poc-test/poc-test.component';
import { CandidateDetailsComponent }      from './candidate-details/candidate-details.component';
import { MainMenuComponent } from './mainmenu/mainmenu.component';
import { MedicalHistoryComponent } from './medical-history/medical-history.component';
import { DataEntryComponent } from './data-entry/data-entry.component';
import { ReviewComponent } from './review/review.component';
import { SearchComponent } from './search/search.component';
import { BloodTestComponent } from './blood-test/blood-test.component';
import { EndoscopyComponent } from './endoscopy/endoscopy.component';
import { MDHCandidateSelectionComponent } from './mdh-candidate-selection/mdh-candidate-selection.component';
import { BloodTestCandidateSelectionComponent } from './blood-test-candidate-selection/blood-test-candidate-selection.component';
import { EndoscopyCandidateSelectionComponent } from './endoscopy-candidate-selection/endoscopy-candidate-selection.component';
import { DiagnosticPathwayCandidateSelectionComponent } from './diagnostic-pathway-candidate-selection/diagnostic-pathway-selection.component';
import { DiagnosticPathwayComponent } from './diagnostic-pathway/diagnostic-pathway.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ToReviewComponent } from './to-review/to-review.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { DSSComponent } from './dss/dss.component';

const routes: Routes = [
	{ path: "", component: LoginComponent }, // NOTE: No authentication guard here since everyone can log in.
	{ path: "resetPassword", component: ResetPasswordComponent }, // NOTE: No authentication guard here since everyone can reset a forgotten password.
	{ path: "resetPass", component: NewPasswordComponent }, // NOTE: No authentication guard here since everyone can reset a forgotten password.
	{ path: "menu", component: MainMenuComponent, canActivate: [AuthGuardService] },
	{ path: "dataEntry", component: DataEntryComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "print", component: MainMenuComponent, canActivate: [AuthGuardService] },      // FIXME later tmp.
	{ path: "reports", component: MainMenuComponent, canActivate: [AuthGuardService] },    // FIXME later tmp.
	{ path: "endoscopy/:candidateID", component: EndoscopyComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "details", component: CandidateDetailsComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "candidateSelection/medicalHistory", component: MDHCandidateSelectionComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "candidateSelection/endoscopy", component: EndoscopyCandidateSelectionComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "candidateSelection/pathway", component: DiagnosticPathwayCandidateSelectionComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dss', 'observer' ] } },
	{ path: "pathway", component: MainMenuComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dss' ] } },    // FIXME lat, 'observer'er tmp.
	{ path: "pathway/:candidateID", component: DiagnosticPathwayComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dss', 'observer' ] } },
	{ path: "medicalHistory", component: MDHCandidateSelectionComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "medicalHistory/:candidateID", component: MedicalHistoryComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "poc", component: PocTestComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "poc/:candidateID", component: PocTestComponent, canActivate: [AuthGuardService], canDeactivate: [CanDeactivateGuard], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "review/:candidateID", component: ReviewComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'reviewer', 'dataacq', 'observer' ] } },
	{ path: "search", component: SearchComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dss', 'observer' ] } },
	{ path: "toreview", component: ToReviewComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'reviewer', 'observer' ] } },
	{ path: "toreview/:show", component: ToReviewComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'reviewer', 'observer' ] } },
	{ path: "statistics", component: StatisticsComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dss', 'observer' ] } },
	{ path: "dss", component: DSSComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dss', 'observer' ] } },
	{ path: "blood", component: BloodTestCandidateSelectionComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "blood/:candidateID", component: BloodTestComponent, canActivate: [AuthGuardService], data: { 'roles': [ 'admin', 'doctor', 'dataacq', 'observer' ] } },
	{ path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
