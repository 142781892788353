import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { ModalService } from '../services/modal.service';

/*
 * A custom modal component to add windows in other components.
 *
 * From: https://jasonwatmore.com/post/2019/04/16/angular-7-custom-modal-window-dialog-box
 */
@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {

	/**
	 * The ID of the modal.
	 *
	 * @access public
	 * @type {string} id
	 */
	@Input() id: string;

	/**
	 * The modal element.
	 *
	 * @access public
	 * @type {any} element
	 */
	private element: any;

	constructor(
		private modalService: ModalService,
		private el: ElementRef
	) {
		this.element = el.nativeElement;
	}

	ngOnInit() {
		let modal = this;

		/*
		 * A modal must always have an ID.
		 * Otherwise, it can neither be opened nor closed.
		 */
		if (!this.id) {
			return
		}

		/*
		 * The modal is last in the application so it has a high z-index.
		 */
		document.body.appendChild(this.element);

		/*
		 * Close the modal whenever the background is clicked.
		 */
		this.element.addEventListener('click', function (e: any) {
			if (e.target.className === 'app-modal') {
				 modal.close();
			}
		});

		/*
		 * Add this modal to the modal service so it can be accessed by other components.
		 */
		this.modalService.add(this);
	}

	/**
	 * When this modal is destroyed, remove it from the modal service.
	 */
	ngOnDestroy(): void {
		this.modalService.remove(this);
		this.element.remove();
	}

	/**
	 * Open the modal.
	 */
	open(): void {
		this.element.classList.add('d-block');
		document.body.classList.add('app-modal-open');
	}

	/**
	 * Close the modal.
	 */
	close(): void {
		this.element.classList.remove('d-block');
		document.body.classList.remove('app-modal-open');
	}

}
