import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	/**
	 * The copyright year.
	 *
	 * @type		{any}
	 * @property	{any} year The current year.
	 */
	@Input() year: any = null;

    /**
	 * The logged-in user's username.
	 *
	 * @type		{string}
	 * @property	{string} username The logged-in user's username.
	 */
	username: string = null;

    /**
	 * The logged-in user's role.
	 *
	 * @type		{string}
	 * @property	{string} role The logged-in user's role.
	 */
	role: string = null;

    /**
	 * The logged-in user's operatorID.
	 *
	 * @type		{string}
	 * @property	{string} operatorID The logged-in user's operatorID.
	 */
	operatorID: string = null;

    /**
     * Create the footer with information about the user.
     */
	constructor() {
        this.username = localStorage.getItem('username');
        this.role = localStorage.getItem('role');
        this.operatorID = localStorage.getItem('operatorID');
    }

	/**
	 * Initialize the footer with a year if none is given.
	 *
	 * @param	{number} year The current year.
	 */
	ngOnInit() {
		if (this.year === null) {
			this.year = new Date(Date.now()).getFullYear();
		}
	}

	/**
	 * A function to check if the application is in development mode.
	 *
	 * @return {bool} A boolean indicating if the application is in development mode.
	 */
	is_dev() {
		return ! window.location.origin.match(/https?:\/\/app\.itama.+/);
	}

}
