import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { ApiService } from '../services/api.service';
import { FormComponent } from '../form/form.component';
import { candidateIDValidator } from "../classes/candidate"

import * as jQuery from "jquery";

@Component({
	selector: 'app-blood-test',
	templateUrl: './blood-test.component.html',
	styleUrls: ['./blood-test.component.css']
})
export class BloodTestComponent extends FormComponent implements OnInit {

	/**
	 * A list of countries from which the user can pick.
	 *
	 * @access public
	 **
	 * @type {string[]} countries
	 */
	public countries: string[] = [];

	/**
	 * A list of locations from which the user can pick.
	 *
	 * @access public
	 **
	 * @type {string[]} locations
	 */
	public locations: { [key: string]: any }[] = [];

	/**
	 * A list of devices returned by the API.
	 *
	 * @access private.
	 *
	 * @type {{ [key: string]: any }[]} devices
	 */
	public devices: { [key: string]: any }[] = [];

	/**
	 * A list of devices from which the user can pick.
	 *
	 * @access public
	 *
	 * @type {{ [key: string]: any }[]} public_devices
	 */
	public public_devices: { [key: string]: any }[] = [];

	/**
	 * A boolean indicating whether the deficiency is required.
	 * By default, it is false. It changes when the TTG-IGA changes.
	 *
	 * @access public
	 *
	 * @type {boolean} deficiency_required
	 */
	public deficiency_required: boolean = false;

	/**
	 * Create the component.
	 *
	 * @param {ActivatedRoute}	route		The currently-loaded route.
	 * @param {ApiService}		apiService	The service that connects to the API.
	 * @param {FormBuilder}		formBuilder	The form builder service.
	 * @param {Router}			router		The router service.
	 */
	constructor(
		apiService: ApiService,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		private router: Router) {
		super(apiService);
		this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; }
		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				this.router.navigated = false;
				window.scrollTo(0, 0);
			}
		});
	}

	/**
	 * When the component loads, create the form.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		super.ngOnInit();

		this.loadDevices();
		this.updateForm();
		this.prepareDateField();

		this.route.params.subscribe(params => {
			let candidateID = params.candidateID ? params.candidateID : '';
			this.form.controls["candidateID"].setValue(candidateID);
		});
	}

	/**
	 * Update the date of test to be a datepicker.
	 */
	prepareDateField() {
		/*
		 * Wait for the date of test field to be rendered.
		 * Then, change the field to an actual date picker.
		 */
		(<any> $("#dateOfTest")).datepicker( {
			format: "dd/mm/yyyy",
			viewMode: "years",
			minViewMode: "days"
		});

		/*
		 * When the date of the test changes, update the date.
		 */
		(<any> $("#dateOfTest")).on("changeDate", ((event) => {
			this.form.controls['dateOfTest'].setValue(event.date ? event.date : event.originalEvent.detail.date);
		}));

		/*
		 * Set the default date to be today.
		 */
		let today = new Date();
		let date = `${String(today.getDate()).padStart(2, "0")}/${String(today.getMonth() + 1).padStart(2, "0")}/${String(today.getFullYear()).padStart(2, "0")}`;
		(<any> $("#dateOfTest")).datepicker("update", date).val(date);
		this.form.controls['dateOfTest'].setValue(date);
	}

	/**
	 * Create a form group for the blood test.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({
			candidateID: [ '', [
				Validators.required,
				candidateIDValidator(5)
			]],
			dateOfTest: [ '', Validators.required],
			test_country: [ localStorage.getItem('blood-country') || 'MLT', Validators.required ],
			test_location: [ '', Validators.required ],
			testType: [ 'igatot', Validators.required ],
			testDevice: [ '', Validators.required ],
			iga_tot: [ '' ],
			emaEval: [ '' ],
			emaPhoto: [ '' ],
			ttg_iga: [ '', Validators.max(100) ],
			ttg_iga_val: [ 'less-100' ],
			ttg_igg: [ '' ],
			dpg_iga: [ '' ],
			dpg_igg: [ '' ],
			aac: [ '' ],
		});
	}

	/**
	 * Update the form when the test type changes.
	 * This function updates whether some fields are required or not.
	 */
	updateForm() {
		const all_fields = [ 'iga_tot', 'emaEval', 'emaPhoto', 'ttg_iga', 'ttg_igg', 'dpg_iga', 'dpg_igg', 'aac' ];
		const dependent_fields = {
			'igatot': [ 'iga_tot' ],
			'ema': [ 'emaEval', 'emaPhoto' ],
			'ttgiga': [ 'ttg_iga', 'ttg_iga_val' ],
			'ttgigg': [ 'ttg_igg' ],
			'dpgiga': [ 'dpg_iga' ],
			'dpgigg': [ 'dpg_igg' ],
			'aac': [ 'aac' ],
		};

		/*
		 * Clear all validators from all optional fields.
		 */
		all_fields.forEach((field) => {
			this.form.controls[field].clearValidators();
			this.form.controls[field].updateValueAndValidity();
		});

		/*
		 * Depending on the test type, add new validators.
		 */
		let test_type = this.form.value['testType'];
		dependent_fields[test_type].forEach((field) => {
			this.form.controls[field].setValidators([ Validators.required ]);
			this.form.controls[field].updateValueAndValidity();
			this.form.controls[field].updateValueAndValidity({ onlySelf: false, emitEvent: true });
		});

	}

	/*
	 * When the TTG-IGA value changes, change the visibility of the deficiency field.
	 */
	onTTGIGAChange() {
		/*
		 * Get the total IGA to check if it is negative.
		 */
		let candidateID = this.form.value['candidateID'];
		let ttg_iga = this.form.value['ttg_iga'];
		let field = 'ttg_igg';

		/*
		 * Hide the deficiency field.
		 * Later, if the field needs to be shown, it is displayed again.
		 */
		this.deficiency_required = false;
		this.form.controls[field].clearValidators();
		this.form.controls[field].updateValueAndValidity();
		if (ttg_iga < 0.1) {
			this.apiService.getBloodTest('igatot', candidateID).subscribe((response) => {
	 			if (response.result == "ok" && response.exams[candidateID].length > 0) {
					response.exams[candidateID].forEach((exam) => {
						if (exam.iga_tot < 0.2) {
			 				this.form.controls[field].setValidators([ Validators.required ]);
							this.form.controls[field].updateValueAndValidity();
							this.form.controls[field].updateValueAndValidity({ onlySelf: false, emitEvent: true });
							this.deficiency_required = true;
						}
					});
	 			} else if (response.result == "error") {
	 				this.error = response.code;
	 			} else {
	 				this.error = "s001";
	 			}
	 		});
		}
	}

	/**
	 * Read the uploaded image.
	 *
	 * The function is triggered whenever an uploaded image changes.
	 * It reads the file that was chosen and provides it as the image source.
	 *
	 * @param {Event}	event		The on-change event.
	 */
	readImage(event) {
		let reader = new FileReader();

		let element = event.target;
		let image = element.value;

		/*
		 * Once the reader loads the data, update the image source.
		 */
		let self = this;
		reader.onload = function (e) {
			let image_data = <string>reader.result
			self.form.controls['emaPhoto'].setValue(event.target.files.item(0));
		}

		/*
		 * First, get the URL of the uploaded file.
		 * It only makes sense to read the picture if one was chosen.
		 */
		if (element.files.length) {
			let url = element.files[0];
			if (url) {
				reader.readAsDataURL(url);
			}
		}
	}

	/**
	 * Load the locations.
	 */
	loadLocations() {
		let test_country = this.form.value['test_country'];
		localStorage.setItem('blood-country', test_country);
		this.apiService.getLocations(test_country, 'hospital').subscribe((response) => {
			if (response.result == "ok") {
				this.locations = response.locations;

				/*
				 * Filter out locations where tests cannot be held.
				 */
				let device_locations = this.devices.map((device) => {
					return device.locName;
				});
				this.locations = this.locations.filter((location) => {
					return device_locations.includes(location.locName);
				});

				this.filterDevices();
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/*
	 * Check whether the user can perform the chosen blood test in the chosen location.
	 */
	canPerformBloodTest() {
		let test_type = this.form.value['testType'];
		let candidateID = this.form.value['candidateID'];

		this.apiService.getBloodTest(test_type, candidateID).subscribe((response) => {
			/*
			 * Remove any existing error from the test location.
			 * It will be added next if need be.
			 */
			let errors = this.form.controls['test_location'].errors;
 			if (errors !== null) {
 				delete errors['blood_test_already_performed'];
 			}
 			this.form.controls['test_location'].setErrors(errors);

			if (response.result == 'ok') {
				response.exams[candidateID].forEach((exam) => {
					if (exam.locationID == this.form.value['test_location']) {
						this.form.controls['test_location'].setErrors({ 'blood_test_already_performed': true });
						return;
					}
				});
			}
		})
	}

	/**
	 * Load the devices.
	 */
	loadDevices() {
		this.apiService.getDevices(this.form.value['testType']).subscribe((response) => {
			if (response.result == "ok") {
				this.devices = (<any>Object).values(response.devices);
				this.filterCountries(); // remove countries where the test cannot be carried out
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * When the devices change, filter the available countries.
	 */
	filterCountries() {
		let locations = this.devices.map((device) => {
			return device.locName;
		});
		this.apiService.getLocations(null, 'hospital').subscribe((response) => {
			if (response.result == "ok") {
				let countries = Array();
				response.locations.forEach((location) => {
					if (locations.includes(location.locName)) {
						countries.push(location.locState);
					}
				})
				this.countries = Array.from(new Set(countries));

				/*
				 * If the currently-selected country does not allow the test, remove its value.
				 */
				if (! this.countries.includes(this.form.value['test_country'])) {
					this.form.controls['test_country'].setValue('');
					this.form.controls['test_location'].setValue('');
				}
				this.loadLocations();
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * When the location changes, hide devices that do not belong to that location.
	 */
	filterDevices() {
		/*
		 * Get the corresponding location name.
		 */
		let location_id = this.form.controls['test_location'].value;
		let location_name = '';
		this.locations.forEach((location) => {
			if (location.locationID == location_id) {
				location_name = location.locName;
				return;
			}
		})

		this.public_devices = this.devices.filter((device) => {
			return device.locName == location_name;
		});

		/*
		 * The user cannot choose a device if there is only one available device.
		 * Therefore set the device ID automatically in this case.
		 * Otherwise, clear the value and let the user choose from a dropdown.
		 */
		if (this.public_devices.length == 1) {
			this.form.controls['testDevice'].setValue(this.public_devices[0].devicesID);
		} else {
			this.form.controls['testDevice'].setValue('');
		}
	}

	/**
	 * Get the magnification of the chosen device.
	 *
	 * @param {number}	id	The ID of the device.
	 *
	 * @return {string}	The magnification of the device.
	 */
	getDeviceMagnification(id: number) {
		for (let i = 0; i < this.devices.length; i++) {
			let device = this.devices[i];
			if (device.id == id) {
				return device.microMagnification;
			}
		}
	}

	/*
	 * When the upload button is clicked, click the hidden file input field.
	 */
	uploadPhoto() {
		jQuery("#emaPhoto").click();

		if (this.error == '0033_0013') { // if the error is due to incorrect file extension, this should be "cleaned" when uploading a new file
			this.error = '';
		}
	}

	/**
	 * When the form is submitted save the blood test in the backend.
	 *
	 * @param {MouseEvent}	event		The click event.
	 */
	onSubmit(event) {
		let new_test = $(event.target).attr("id") == "submit-new";
		super.onSubmit(event);
		if (! this.form.valid) { return }

		let result = Object.assign({}, this.form.value);
		result.dateOfTest = (<any> $("#dateOfTest")).data().datepicker.date;
		let test_type = this.form.value['testType'];

		if (test_type == 'ema') {
			result.magnification = this.getDeviceMagnification(result.testDevice);
			jQuery('.ema-loading').removeClass('d-none');
		}

		this.apiService.bloodTest(result, test_type).subscribe((response) => {
			if (response.result == "ok") {
				if (this.deficiency_required) {
					this.apiService.bloodTest(result, "ttgigg").subscribe((response) => {
						if (response.result == "ok") {
							this.reroute(new_test);
						} else if (response.result == "error") {
							this.error = response.code;
						} else {
							this.error = "s001";
						}
					});
				} else {
					this.reroute(new_test);
				}
			} else if (response.result == "error") {
				jQuery('.ema-loading').addClass('d-none'); // some error occured, if ema loading msg is displayed then hide
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * Go back to the data entry menu.
	 *
	 * @param {boolean}		new_test	A boolean indicating whether to return to start a new blood test or go back to the data entry menu.
	 */
	reroute(new_test: boolean) {
		jQuery('.ema-loading').addClass('d-none');
		jQuery('.success').removeClass('d-none');
		setTimeout(() => {
			if (new_test) {
				this.router.navigate(['blood', this.form.value['candidateID']]);
			} else {
				this.router.navigate(['dataEntry']);
			}
		}, 3000);
	}

}
