import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

	/**
	 * Create the authentication guard.
	 *
	 * @param {AuthService}	authService	The authentication service to use.
	 * @param {Router}		router		The router service.
	 */
	constructor(private authService: AuthService,
  				private router: Router) { }

	/**
	 * A function that guards against illicit access.
	 * The function calls the authentication service for validation.
	 * If the user is not authenticated, they are redirected to the login page.
	 *
	 * @return {boolean} A boolean indicating whether the user has the necessary clearance.
	 */
  	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (! this.authService.hasToken() || this.authService.hasExpired() || ! this.authService.hasOperatorID()) {
			this.router.navigate([""]);
			return false;
		}

        if (route.data.roles) {
            if (! route.data.roles.includes(this.authService.getRole().toLowerCase())) {
                this.router.navigate([""]);
    			return false;
            }
        }

		return true;
	}

}
