
declare var $: any;

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from '../services/api.service';

import { Candidate } from "../classes/candidate";
import { FormComponent } from '../form/form.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '../services/modal.service';

import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
	selector: 'app-to-dss',
	templateUrl: './dss.component.html',
	styleUrls: ['./dss.component.css']
})
export class DSSComponent extends FormComponent implements OnInit {

	public predefinedFilterSelection: number = -1;
	public pathwayID: string = '';

	table: any = null;

	searchForm: FormGroup; // the form holding the search info.

	/**
	 * A list of diagnostic pathways from which the user can pick.
	 *
	 * @access public
	 **
	 * @type {string[]} pathways
	 */
	public pathways: { [key: string]: any }[] = [];
	public pathwaysDescription: { [id: string]: string } = {};
	private searched: boolean = false;
	public results: boolean = true;



	/**
	 * The API service is used to fetch
	 *
	 * @param {ApiService}		apiService		The service that connects to the API.
	 */
	constructor(
		apiService: ApiService,
		private router: Router,
		private formBuilder: FormBuilder,
		public modalService: ModalService,
		private confirmationDialogService: ConfirmationDialogService
	) {
		super(apiService);
	}

	/**
	 * When the component loads, get a list of tests that need reviewing.
	 */
	ngOnInit() {
		super.ngOnInit();

		this.searchForm = this.formBuilder.group({
			predefinedFilters: [''],
			pathwayID: [''],
		});

		this.table = $('#datatable').DataTable({
			columnDefs: [{
				orderable: false,
				className: 'select-checkbox',
				targets: 0,
				defaultContent: '',
			}],
			columns: [
				null,
				null,
				null,
				null,
				null,
				null,
				{ "width": "40%" }
			],
			select: {
				style: 'os',
				selector: 'td:first-child'
			},
			order: [[1, 'asc']],
			//			deferRender: true // do not use this -- as node() becomes null and you will be unable to set the id.
		});

		this.loadPathways();
	}

	changeFilter() {
		this.predefinedFilterSelection = parseInt(this.searchForm.get('predefinedFilters').value);
		this.table.clear();
		this.searched = false;
	}

	changePathway() {
		this.pathwayID = this.searchForm.get('pathwayID').value;
		this.table.clear();
		this.searched = false;
	}


	/**
	 * Load the pathways into the form.
	 */
	loadPathways() {
		this.apiService.getPathways().subscribe((response) => {
			if (response.result == "ok") {
				this.pathways = response.diagnosticpathways;
				this.pathways.forEach(p => {
					this.pathwaysDescription[p.pathwayID] = p.pwyDescr;
				})

			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	selectAll($e) {
		let selectAll = <HTMLInputElement>document.getElementById("selectAll");
		Array.from(document.querySelectorAll("input[name='candidates']")).map(elem => (<HTMLInputElement>elem).checked = selectAll.checked);
	}

	markAsNonCeliac($e) {
		let candidateIDs = Array.from(document.querySelectorAll("input[name='candidates']:checked")).map(elem => (<HTMLInputElement>elem).value);
		candidateIDs.forEach(cid => {
			this.apiService.markAsNonCeliac(cid).subscribe(response => {
				if (response.result == "ok") {
					console.log(`Candidate ${cid} marked as non celiac`);
					this.table.row(`#${cid}`).remove().draw();
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					console.log(`An error occured (code: ${response.code}).  Unable to mark candidate ${cid} as non celiac.  Error Message: ${response.message}`);
				}
			});
		});
	}

	markAsCeliac($e) {
		let candidateIDs = Array.from(document.querySelectorAll("input[name='candidates']:checked")).map(elem => (<HTMLInputElement>elem).value);
		candidateIDs.forEach(cid => {
			this.apiService.markAsCeliac(cid).subscribe(response => {
				if (response.result == "ok") {
					console.log(`Candidate ${cid} marked as celiac`);
					this.table.row(`#${cid}`).remove().draw();
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					console.log(`An error occured (code: ${response.code}).  Unable to mark candidate ${cid} as celiac.  Error Message: ${response.message}`);
				}
			});
		});
	}

	markAsRefusedFurtherTesting($e) {
		let candidateIDs = Array.from(document.querySelectorAll("input[name='candidates']:checked")).map(elem => (<HTMLInputElement>elem).value);
		candidateIDs.forEach(cid => {
			this.apiService.markAsRefusedFurtherTesting(cid).subscribe(response => {
				if (response.result == "ok") {
					console.log(`Candidate ${cid} marked as refused further testing`);
					this.table.row(`#${cid}`).remove().draw();
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					console.log(`An error occured (code: ${response.code}).  Unable to mark candidate ${cid} as refused further testing.  Error Message: ${response.message}`);
				}
			});
		});
	}


	proceedToNextTests($e) {
		let candidateIDs = Array.from(document.querySelectorAll("input[name='candidates']:checked")).map(elem => (<HTMLInputElement>elem).value);
		if (candidateIDs.length > 0) { // don't show confirmation dialog otherwise
			this.confirmationDialogService.confirm('Confirm your action', 'Do you really want to move these candidates to the next step?')
				.then((confirmed) => {
					if (confirmed) {
						candidateIDs.forEach(cid => {
							this.apiService.proceedToNextTests(cid).subscribe(response => {
								if (response.result == "ok") {
									console.log(`Candidate ${cid} moved to next tests`);
									this.table.row(`#${cid}`).remove().draw();
								} else {
									console.log(`An error occured (code: ${response.code}).  Unable to move candidate ${cid} to next steps.  Error Message: ${response.message}`);
								}
							});

						});
					}
				})
				.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
		}
	}


	loadCandidates($e) {
		//$e.preventDefault();
		this.searched = true;
		this.results = true;
		// remove any previous results
		this.table.clear();

		// get the new results
		if (this.predefinedFilterSelection == 0) {
			this.apiService.getCandidatesWithNegativePOCTAndNegativeMDH(this.pathwayID).subscribe(response => {
				if (response.result == "ok") {
					response["candidates with no YESes and >1 NEG judgements"].forEach((obj, i) => {

						let age = 0;
						if (obj.candidateBirthdate) {
							const date = obj.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						this.table.row.add([
							`<input type="checkbox" value="${obj.candidateID}" name="candidates" />`,
							obj.candidateID,
							obj.town,
							obj.country,
							Math.round(age * 100) / 100.,
							obj.candidateGender,
							this.readablePathway(obj.pathwayID)
						]).node().id = obj.candidateID;
					});
					this.table.draw(false);
					if (this.table.data().count() == 0) {
						this.results = false;
					}
				} else {
					console.error(`An error has happened ${response.code}.  Message: ${response.message}`);
				}

			});
		} else if (this.predefinedFilterSelection == 1) {
			this.apiService.getCandidatesWithPositivePOCTAndMHQComplete(this.pathwayID).subscribe(response => {

				if (response.result == "ok") {
					response["ConfirmedPOS_MHQdone"].forEach((obj, i) => {

						let age = 0;
						if (obj.candidateBirthdate) {
							const date = obj.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						this.table.row.add([
							`<input type="checkbox" value="${obj.candidateID}" name="candidates" />`,
							obj.candidateID,
							obj.town,
							obj.country,
							Math.round(age * 100) / 100.,
							obj.candidateGender,
							this.readablePathway(obj.pathwayID)
						]).node().id = obj.candidateID;

					});
					this.table.draw(false);
					if (this.table.data().count() == 0) {
						this.results = false;
					}

				} else {
					console.error(`An error has happened ${response.code}.  Message: ${response.message}`);
				}
			});

		} else if (this.predefinedFilterSelection == 2) {
			this.apiService.getCandidatesWithFivePositiveQuestionsAndPOCTDone(this.pathwayID).subscribe(response => {
				if (response.result == "ok") {
					response["5_yeses_and_PoCT"].forEach((obj, i) => {

						let age = 0;
						if (obj.candidateBirthdate) {
							const date = obj.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						this.table.row.add([
							`<input type="checkbox" value="${obj.candidateID}" name="candidates" />`,
							obj.candidateID,
							obj.town,
							obj.country,
							Math.round(age * 100) / 100.,
							obj.candidateGender,
							this.readablePathway(obj.pathwayID)
						]).node().id = obj.candidateID;

					});
					this.table.draw(false);
					if (this.table.data().count() == 0) {
						this.results = false;
					}

				} else {
					console.error(`An error has happened ${response.code}.  Message: ${response.message}`);
				}
			});
		} else if (this.predefinedFilterSelection == 3) {
			this.apiService.getCandidatesWithHighTTGIgA(this.pathwayID).subscribe(response => {
				if (response.result == "ok") {
					response["List"].forEach((obj, i) => {

						let age = 0;
						if (obj.candidateBirthdate) {
							const date = obj.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						this.table.row.add([
							`<input type="checkbox" value="${obj.candidateID}" name="candidates" />`,
							obj.candidateID,
							obj.town,
							obj.country,
							Math.round(age * 100) / 100.,
							obj.candidateGender,
							this.readablePathway(obj.pathwayID)
						]).node().id = obj.candidateID;

					});
					this.table.draw(false);
					if (this.table.data().count() == 0) {
						this.results = false;
					}

				} else {
					console.error(`An error has happened ${response.code}.  Message: ${response.message}`);
				}
			});
		} else if (this.predefinedFilterSelection == 4) {
			this.apiService.getCandidatesWithLowIgATot(this.pathwayID).subscribe(response => {
				if (response.result == "ok") {
					response["List"].forEach((obj, i) => {

						let age = 0;
						if (obj.candidateBirthdate) {
							const date = obj.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						this.table.row.add([
							`<input type="checkbox" value="${obj.candidateID}" name="candidates" />`,
							obj.candidateID,
							obj.town,
							obj.country,
							Math.round(age * 100) / 100.,
							obj.candidateGender,
							this.readablePathway(obj.pathwayID)
						]).node().id = obj.candidateID;

					});
					this.table.draw(false);
					if (this.table.data().count() == 0) {
						this.results = false;
					}

				} else {
					console.error(`An error has happened ${response.code}.  Message: ${response.message}`);
				}
			});
		} else if (this.predefinedFilterSelection == 5) {
			this.apiService.getCandidatesWithFivePositiveQuestionsAndPOCTNegative(this.pathwayID).subscribe(response => {
				if (response.result == "ok") {
					response["5_yeses_and_PoCT_NEG"].forEach((obj, i) => {

						let age = 0;
						if (obj.candidateBirthdate) {
							const date = obj.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						this.table.row.add([
							`<input type="checkbox" value="${obj.candidateID}" name="candidates" />`,
							obj.candidateID,
							obj.town,
							obj.country,
							Math.round(age * 100) / 100.,
							obj.candidateGender,
							this.readablePathway(obj.pathwayID)
						]).node().id = obj.candidateID;

					});
					this.table.draw(false);
					if (this.table.data().count() == 0) {
						this.results = false;
					}

				} else {
					console.error(`An error has happened ${response.code}.  Message: ${response.message}`);
				}
			});
		} else {
			console.error(`Unknown predefined filter selection value: ${this.predefinedFilterSelection}`);
		}
	}

	public showResults(): boolean {
		return !this.searched;
	}

	public showDiagnosticButtons(): boolean {
		if (!this.table.data().count()) {
			return false;
		}
		return true;
	}

	public showEnableTests(): boolean {
		if (!this.table.data().count()) {
			return false;
		}
		// only malta screening and parents of kids follow pathway and order tests
		if ((this.pathwayID != 'mlt-screening') &&
			(this.pathwayID != 'ita-kidsparents')) {
			return false;
		}
		if (this.predefinedFilterSelection == 0) { // we do not want for neg poct and neg MHQ to have this button
			return false;
		}
		return true;
	}

	public readablePathway(pathwayID: string): string {
		if (pathwayID in this.pathwaysDescription) {
			return this.pathwaysDescription[pathwayID];
		}
		return pathwayID;
	}
}
