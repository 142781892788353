import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../services/api.service';
import { FormComponent } from '../form/form.component';

export function matching(value: string): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} | null => {
		return value == control.value ? null : { matching: false };
	};
}

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent extends FormComponent implements OnInit {

	/**
	 * On initialization, create the form.
	 *
	 * @param {ActivatedRoute}	route		The currently-loaded route.
	 * @param {ApiService}		apiService		The service that connects to the API.
	 * @param {FormBuilder}		formBuilder		The form builder service.
	 * @param {Router}			router		The router service.
	 */
	constructor(
		apiService: ApiService,
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		public router: Router
	) {
		super(apiService);
	}

	/**
	 * On initialization, create the form.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		super.ngOnInit();

		this.route.queryParams.subscribe(params => {
			console.log(this.route);
			let token = params.resetToken ? params.resetToken : '';
			this.form.controls["token"].setValue(token);
		});
	}

	/**
	 * When the password changes, update the validator for the repeated password.
	 */
	onPasswordChange() {
		this.form.controls['repeat_password'].setValidators([ matching(this.form.value['password']) ]);
		this.form.controls['repeat_password'].updateValueAndValidity();
	}

	/**
	 * Create a form group for the form.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			repeat_password: [''],
			token: [ '', Validators.required ],
		});
	}

	/**
	 * Get the form's values and send them to the API.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	newPassword(event) {
		super.onSubmit(event);
		const result = Object.assign({}, this.form.value);

		this.apiService.newPassword(result.username, result.token, result.password).subscribe((response) => {
			console.log('here');
			if (response.result == "ok") {
				jQuery('.success').removeClass('d-none');
				setTimeout(() => {
					this.router.navigate(['']);
				}, 3000);
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

}
