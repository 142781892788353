import { BrowserModule } from '@angular/platform-browser';
// NOTE: FormsModule not needed if reactive forms are used. Retained for backwards compatibility.
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from './services/api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PocTestComponent } from './poc-test/poc-test.component';
import { MainMenuComponent } from './mainmenu/mainmenu.component';
import { CandidateDetailsComponent } from './candidate-details/candidate-details.component';
import { MedicalHistoryComponent } from './medical-history/medical-history.component';
import { DataEntryComponent } from './data-entry/data-entry.component';
import { FirstErrorPipe } from './pipes/first-error.pipe';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ReviewComponent } from './review/review.component';
import { SearchComponent } from './search/search.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { FormComponent } from './form/form.component';
import { FloorPipe } from './pipes/floor.pipe';
import { BloodTestComponent } from './blood-test/blood-test.component';
import { EndoscopyComponent } from './endoscopy/endoscopy.component';
import { ModalComponent } from './modal/modal.component';
import { CandidateSelectionComponent } from './candidate-selection/candidate-selection.component';
import { MDHCandidateSelectionComponent } from './mdh-candidate-selection/mdh-candidate-selection.component';
import { BloodTestCandidateSelectionComponent } from './blood-test-candidate-selection/blood-test-candidate-selection.component';
import { EndoscopyCandidateSelectionComponent } from './endoscopy-candidate-selection/endoscopy-candidate-selection.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DiagnosticPathwayComponent, RowPipe } from './diagnostic-pathway/diagnostic-pathway.component';
import { DiagnosticPathwayCandidateSelectionComponent } from './diagnostic-pathway-candidate-selection/diagnostic-pathway-selection.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ToReviewComponent } from './to-review/to-review.component';
import { DSSComponent } from './dss/dss.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PocTestComponent,
    MainMenuComponent,
    CandidateDetailsComponent,
    MedicalHistoryComponent,
    DataEntryComponent,
    FirstErrorPipe,
    HeaderComponent,
    FooterComponent,
    ReviewComponent,
    SearchComponent,
    LanguagePickerComponent,
    FormComponent,
    FloorPipe,
    BloodTestComponent,
    EndoscopyComponent,
    ModalComponent,
    CandidateSelectionComponent,
    MDHCandidateSelectionComponent,
    EndoscopyCandidateSelectionComponent,
    BloodTestCandidateSelectionComponent,
    DiagnosticPathwayCandidateSelectionComponent,
    ResetPasswordComponent,
    DiagnosticPathwayComponent,
    NewPasswordComponent,
    ToReviewComponent,
    RowPipe,
    DSSComponent,
    StatisticsComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [ApiService, ConfirmationDialogService ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() { }

}
