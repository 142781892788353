import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { switchMap } from 'rxjs/operators';

import * as $ from 'jquery';

import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { FormComponent } from '../form/form.component';

import {
	Candidate,
	candidateIDValidator,
} from "../classes/candidate"

@Component({
	selector: 'app-review',
	templateUrl: './review.component.html',
	styleUrls: ['./review.component.css']
})

export class ReviewComponent extends FormComponent implements OnInit {
	/**
	 * Controls the results review page.
	 * This page shows the 'before' and 'after' images of a POC test side-by-side.
	 * The staff member then assigns a judgement - positive, negative or inconclusive.
	 */

	/**
 	 * The first POC test image.
 	 *
 	 * @type {string}	poctPhoto1
 	 */
 	poctPhoto1:string = '';

   /**
	* The second POC test image.
	*
	* @type {string}	poctPhoto2
	*/
   poctPhoto2:string = '';

	/**
	 * An edit flag to check whether the form signifies the creation of a judgement or an update to an existing one.
	 *
	 * @access private
	 *
	 * @type {boolean} edit
	 */
	private edit: boolean = false;

	/**
	 * A flag to check whether the user can assign a judgement or not (doctors cannot judge if there are already 2 judgements).
	 *
	 * @access public
	 *
	 * @type {boolean} can_judge
	 */
	public can_judge: boolean = true;

	/**
	 * Set up the component's resources.
	 *
	 * @param {ApiService}			apiService		The service that connects to the API.
	 * @param {AuthService}			authService		The service that checks whether a user can access certain pages.
	 * @param {FormBuilder}			formBuilder		The form builder service.
	 * @param {ModalService}		modalService	A service used to show modals.
	 * @param {ActivatedRoute}		route			The currently-loaded route.
	 * @param {Router}				router			The router service.
	 */
	constructor(
		apiService: ApiService,
		private authService: AuthService,
		private formBuilder: FormBuilder,
		public modalService: ModalService,
		private route: ActivatedRoute,
		private router: Router) {
		super(apiService);
	}

	/**
	 * Whenever the component is initialized, load the images.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		super.ngOnInit();
		this.route.params.subscribe(params => {
			let candidateID = params.candidateID;

			this.loadPOC(candidateID);

			/*
			 * Check whether the staff member has already given their judgement.
			 */
			this.apiService.getCandidateJudgements(candidateID).subscribe((response) => {
				if (response.result == "ok") {
					let operatorID = localStorage.getItem("operatorID");

					if (response.results[candidateID]) {
						$.each(response.results[candidateID].judgements, (index, judgement) => {
							/*
							 * Check if the judgement was given by this operator.
							 * If it was, load the judgement into this form and update the edit flag.
							 */
							if (judgement.operator.operatorID == operatorID) {
								this.form.controls["judgement"].setValue(judgement.poctRes);
								this.edit = true;
							}
						});

						// a staff member can judge if they have already assigned a judgement or there is only one judgement
						this.can_judge = [ "Admin", "Reviewer" ].includes(this.authService.getRole()) || this.edit || response.results[candidateID].judgements.length <= 1;
					}
				}
			});

			if (candidateID) {
				this.calculateAge(candidateID);
			}
		});
	}

	/**
	 * Create a form group for the POC test.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({
			candidateID: [0, [
				Validators.required,
				candidateIDValidator(5)
			]],
			years: [''],
			months: [''],
			operatorID: [
				localStorage.getItem("operatorID"), [
					Validators.required
				]
			],
			judgement: ['', Validators.required ],
		});
	}

	/**
	 * Load the POC test images for the candidate with the given ID.
	 *
	 * @param {string}	candidateID	The ID of the candidate whose POC test images will be shown.
	 */
	loadPOC(candidateID: string) {
		this.form.controls["candidateID"].setValue(candidateID);
		this.apiService.getPOCTestDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				response = this.apiService.constructPOCTestImageURLs(response);
				this.poctPhoto1 = response.poctPhoto1;
				this.poctPhoto2 = response.poctPhoto2;
				jQuery(".btn[type='submit']").removeAttr("disabled");
			}
		});
	}

	/**
	 * Calculate the candidate's age.
	 *
	 * @param {number}	candidateID		The candidate's ID.
	 */
	calculateAge(candidateID) {
		this.apiService.getCandidateDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				const date = response.candidateBirthdate.split('-');
				let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
				let age = Candidate.calculateAge(dateOfBirth);
				this.form.controls["years"].setValue(Math.floor(age));
				this.form.controls["months"].setValue(Math.floor((age % 1) * 12.));
				jQuery(".btn[type='submit']").removeAttr("disabled");
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * Get the form's values and send them to the API.
	 */
	onSubmit() {
		super.onSubmit(event);
		if (! this.form.valid) {
			$("#missing_input").removeClass('d-none');
			return
		}

		let judgement = this.form.value['judgement'];
		/*
		 * If a judgement has been set, submit it.
		 * Otherwise, display an error.
		 */
		this.apiService.saveJudgement(
			this.form.value["candidateID"],
			judgement, this.edit
		).subscribe((response) => {
			if (response.result == "ok") {
				if (this.authService.getRole().toLowerCase() == 'reviewer') {
					this.router.navigate(['toreview', 'contrasting']);
				} else if (this.authService.getRole().toLowerCase() == 'dataacq') {
					this.router.navigate([ 'dataEntry' ])
				} else {
					this.router.navigate(['toreview', 'all']);
				}
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

}
