import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

	/**
	 * Ask user to confirm an action.
	 *
	 * @return {bool}	A boolean indicating whether the user confirmed the action.
	 */
	confirm(message?: string): Observable<boolean> {
		const confirmation = window.confirm(message || 'Are you sure?');
		return of(confirmation);
	};

}
