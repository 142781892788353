import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.css']
})
export class DataEntryComponent implements OnInit {

	/**
     * @param {Router}		router		The router service.
     */
    constructor(private router: Router) { }

	ngOnInit() {
	}

}
