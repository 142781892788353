import { Component, OnInit } from '@angular/core';
import Popper, {PopperOptions} from 'popper.js';

import { Inject, LOCALE_ID } from '@angular/core';

@Component({
	selector: 'app-language-picker',
	templateUrl: './language-picker.component.html',
	styleUrls: ['./language-picker.component.css']
})
export class LanguagePickerComponent implements OnInit {

	/**
	 * The flag to display.
	 * The flag is set based on the locale when the component loads.
	 *
	 * @type {string} flag
	 */
	flag: string;

	/**
	 * Inject the locale into the component.
	 */
	constructor( @Inject(LOCALE_ID) public locale: string ) {
	}

	/**
	 * When the component initializes, update the flag to show in the dropdown.
	 */
	ngOnInit() {
		switch(this.locale) {
			case "en-US":
			case "en-GB":
				this.flag = "gb";
				break;
			case "it":
				this.flag = "it";
				break;
			default:
				this.flag = "gb";
		}
	}

	/**
	 * Redirect to the correct locale when the language changes.
	 *
	 * @param {string}	locale	The chosen locale.
	 */
	languageRedirect(locale: string) {
		let url = window.location.href;
		let redirectURL = url.replace(/\/itama\/((it|en)\/)?/ig, `/itama/${locale}/`);
		window.location.replace(redirectURL);
	}

}
