import { Component, OnInit, Inject, HostListener, LOCALE_ID, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';

import { Observable } from 'rxjs';

import * as $ from "jquery";

import { ApiService } from '../services/api.service';
import { DialogService } from '../services/dialog.service';
import { FormComponent } from '../form/form.component';
import { FloorPipe } from '../pipes/floor.pipe';

import { MedicalHistoryComponent } from '../medical-history/medical-history.component';

import {
	Candidate,
	candidateIDValidator,
} from "../classes/candidate"

@Component({
  selector: 'app-poc-test',
  templateUrl: './poc-test.component.html',
  styleUrls: ['./poc-test.component.css']
})

export class PocTestComponent extends FormComponent implements OnInit {

	/**
	 * Controls the POC Test page.
	 *
	 * The POC Test page has a simple workflow with two states.
	 * In the first state, a first picture of the POC test is taken.
	 * After a number of minutes, the page transitions to a second state.
	 * When the second state starts, the first picture cannot be changed.
	 * In the second state, a second picture can be taken and uploaded.
	 */

	/**
 	 * The Math library.
 	 */
 	Math: any;

	/**
	 * The time it takes to transition from the first state to the second state.
	 * The time is recorded in seconds, and defaults to 10 minutes.
	 *
	 * @type {number} timer
	 */
	timer_length: number = 600;

	/**
	 * The remaining time before transitioning from the first state to the second state.
	 *
	 * @type {number} timer
	 */
	timer: number;

	/**
	 * The time when the timer started to operate.
	 *
	 * @type {number} timer_start
	 */
	timer_start: number;

  /**
	 * A boolean that does not show a prompt when nagivating away from the page.
	 * It is set to false when the timer starts, and becomes true as soon as the images have been uploaded.
	 * This means users can only navigate away either before starting the POC test or after completing it.
	 *
	 * @type {boolean} allow_deactivate
	 */
	allow_deactivate: boolean = true;

	/**
	 * The interval ID for the countdown.
	 * This ID is stored so that it can be cleared once the timer reaches 0.
	 *
	 * @access private
	 *
	 * @type {number} timerIntervalID
	 */
	private timerIntervalID: number;

    /**
     * The form error.
     *
     * @type {string} error
     */
    error: string;

	/**
	 * A boolean indicating whether to show the medical history questionnaire.
	 *
	 * @access public
	 * @type {boolean} withMDH
	 */
	public withMDH: boolean = false;

	/**
	 * The MDH component that may be embedded in this component.
	 *
	 * @type(MedicalHistoryComponent)
	 */
	@ViewChild(MedicalHistoryComponent) mdhComponent: MedicalHistoryComponent;

	/**
	 * The pictures, as Files, that the user uploads.
	 *
	 * @access private
	 *
	 * @type { [key: string]: File | null }	pictures
	 */
	private pictures:{ [key: string]: File | null } = {
		"firstPicture": null,
		"secondPicture": null
	};

	/**
	 * On initialization, create the form.
	 *
	 * @param {ActivatedRoute}	route			The currently-loaded route.
	 * @param {ApiService}		apiService		The service that connects to the API.
	 * @param {DialogService}	dialogService	The service that shows dialog messages.
	 * @param {FormBuilder}		formBuilder		The form builder service.
	 * @param {Router}			router			The router service.
	 */
	constructor(
			@Inject(LOCALE_ID) public locale: string,
			private route: ActivatedRoute,
			apiService: ApiService,
			private dialogService: DialogService,
			private formBuilder: FormBuilder,
			private router: Router) {
		super(apiService);
		/*
		 * If the domain name is not the application, reduce the timer length.
		 */
		let domain = window.location.origin;
		if (! domain.match(/https?:\/\/app\.itama.+/)) {
			this.timer_length = 60;
		}

		this.timer = this.timer_length;
	}

	/**
	 * Before moving to another page, show a confirmation prompt.
	 */
	canDeactivate(): Observable<boolean> | boolean {
		return this.allow_deactivate || this.dialogService.confirm('Are you sure you want to leave this page?');
	}

	/**
	 * Before refreshing, show a confirmation prompt.
	 */
	@HostListener('window:beforeunload', [ '$event' ])
	confirmNavigate($event) {
		$event.returnValue = 'Are you sure you want to leave this page?';
	}

	/**
	 * When the component loads, the form is created.
	 * If a candidate ID is provided, it is loaded into the field directly.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		super.ngOnInit();
		this.Math = Math;
		this.route.params.subscribe(params => {
			let candidateID = params.candidateID ? params.candidateID : '';
			this.form.controls["candidateID"].setValue(candidateID);
			if (candidateID) {
				this.loadCandidate(candidateID);
			}
		});
	}

	/**
	 * Load the details of the candidate having the given ID.
	 *
	 * @param {string}	candidateID	The ID of the candidate whose details will be loaded.
	 *
	 */
	loadCandidate(candidateID: string) {
		/*
		 * Remove all errors.
		 * They can be set later if the POC tests already exist.
		 */
		this.form.controls['candidateID'].markAsTouched();
		let errors = this.form.controls['candidateID'].errors;
		if (errors !== null) {
			delete errors['candidate_does_not_exist'];
			delete errors['cannot_perform_poc_test'];
			delete errors['poc_test_already_performed'];
		}
		this.form.controls['candidateID'].setErrors(errors);

		/*
		 * Calculate the age first.
		 * Then, check if the candidate can perform the POC test in the pathway.
		 * Finally, check if the candidate does not already have performed a POC test.
		 */
		this.apiService.getCandidateDetails(candidateID).subscribe((response) => {
			if (response.result == "ok") {
				const date = response.candidateBirthdate.split('-');
				let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
				let age = Candidate.calculateAge(dateOfBirth);
				this.form.controls["years"].setValue(Math.floor(age));
				this.form.controls["months"].setValue(Math.floor((age % 1) * 12.));
				/*
				 * Check if the user can perform a POC test.
				 */
				this.apiService.canFollowPathway(candidateID, 'poct').subscribe((response) => {
					if (response.result == "ok") {
						if (! response.check) {
							this.form.controls['candidateID'].setErrors({ 'cannot_perform_poc_test': true });
						} else {
							/*
							 * Check that the user hasn't already performed a POC test.
							 */
							this.apiService.getPOCTestDetails(candidateID).subscribe((response) => {
								if (response.result == 'ok') {
									this.form.controls['candidateID'].setErrors({ 'poc_test_already_performed': true });
								} else if (response.result == 'error') { } else {
									this.error = 's001';
								}
							});
						}
					} else if (response.result == 'error') {
						this.error = response.code;
					} else {
						this.error = 's001';
					}
				});
			} else if (response.result == "error") {
				this.error = response.code;
				let errors = this.form.controls['candidateID'].errors;
				if (errors === null) {
					errors = new Object();
				}

				errors['candidate_does_not_exist'] = true;
				this.form.controls['candidateID'].setErrors(errors);
			} else {
				this.error = "s001";
			}
		});

	}

	/**
	 * Validate the POC test ID.
	 * A valid ID is made up of digits.
	 *
	 * @param {number}	length	The length of the candidate number.
	 *
	 * @return {bool}	A boolean indicating whether the POC test ID is valid.
	 */
	pocIDValidator(length: number): ValidatorFn {
		let pattern = new RegExp(`^[0-9]{${length}}$`)
		return (control: AbstractControl): {[key: string]: any} | null => {
			const valid = pattern.test(control.value);
			return valid ? null : { "invalidPOCID": {value: control.value} };
		};
	}

	/**
	 * Create a form group for the POC test.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({
			candidateID: [0, [
				Validators.required,
				candidateIDValidator(5)
			]],
			months: [''],
			years: [''],
			firstPicture: ['', [ Validators.required ]],
			firstPictureTimestamp: [0, [ Validators.required ]],
			secondPicture: ['', [ Validators.required ]],
			secondPictureTimestamp: [0, [ Validators.required ]],
		});
	}

	/**
	 * When the first picture is uploaded, start the countdown.
	 *
	 * Once the countdown reaches 0, the first phase is over.
	 * At this point, a second picture can be taken.
	 */
	startCountdown() {
		if (!this.timerIntervalID) {
			this.timer_start = (new Date()).getTime();
			this.allow_deactivate = false;
			this.timerIntervalID = window.setInterval(() => {
				this.decrementTimer()
			}, 1000);
		}
	}

	/**
	 * Count down the timer.
	 *
	 * The function stops counting down when the timer reaches 0.
	 * When it reaches 0, the interval is cleared.
	 */
	decrementTimer() {
		this.timer = this.timer_length - ((new Date()).getTime() - this.timer_start) / 1000.;
		this.timer = Math.max(0, Math.ceil(this.timer));
		if (this.timer == 0) {
			clearInterval(this.timerIntervalID);
		}

		/*
		 * Play an alarm sound every few seconds before the timer expires.
		 */
		if (this.timer <= 11 && ! ((this.timer - 1) % 5) && this.timer > 0) {
			let audio = new Audio();
			audio.src = "assets/audio/alarm.mp3";
			audio.load();
			audio.play();
		}
	}

	/**
	 * Read the uploaded image and display it in the adjacent image.
	 *
	 * The function is triggered whenever an uploaded image changes.
	 * It reads the file that was chosen and provides it as the image source.
	 *
	 * @param {Event}	event		The on-change event.
	 */
	readImage(event) {
		let reader = new FileReader();

		let element = event.target;
		let image = element.value;

		/*
		 * Once the reader loads the data, update the image source.
		 */
		let self = this;
		reader.onload = function (e) {
			let image = $(element).closest(".form-group")
								  .find("img");
			image.attr("src", <string>reader.result);
			image.show();

			/*
			 * Find the name of the input field where to store the timestamp.
			 * Set the timestamp (in seconds) when the picture was uploaded.
			 */
			const name: string = $(element).closest(".form-group")
				.find(".timestamp").attr("name");
			self.form.controls[name].setValue(parseInt(String(Date.now() / 1000.)));

			const picture: string = event.target.name;
			self.pictures[picture] = event.target.files.item(0);
		}

		/*
		 * First, get the URL of the uploaded file.
		 * It only makes sense to read the picture if one was chosen.
		 */
		if (element.files.length) {
			let url = element.files[0];
			if (url) {
				reader.readAsDataURL(url);
			}
		} else {
			$(element).closest(".form-group")
					  .find("img")
					  .hide();
		}
	}

	/**
	 * Get the form's values and send them to the API.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	onSubmit(event) {
		super.onSubmit(event);
		$("#missing_input").addClass('d-none');
		$("#missing_timestamps").addClass('d-none');
		if (! this.form.valid) {
			if (! this.form.controls["firstPicture"].valid || ! this.form.controls["secondPicture"].valid) {
				$("#missing_input").removeClass('d-none');
			} else if (! this.form.controls["firstPictureTimestamp"].valid || ! this.form.controls["secondPictureTimestamp"].valid) {
				$("#missing_timestamps").removeClass('d-none');
			}
			return
		}

		jQuery('.success').removeClass('d-none'); // show a success notice while the POC test uploads
		this.apiService.createPOCTest(this.form.value["candidateID"],
			this.pictures["firstPicture"], this.form.value["firstPictureTimestamp"],
			this.pictures["secondPicture"], this.form.value["secondPictureTimestamp"],
			true
		).subscribe((response) => {
			jQuery('.success').addClass('d-none'); // hide the success notice to make space for errors or redirect
			if (response.result == "ok") {
				/*
				 * Redirect based on which button was clicked.
				 */
				this.allow_deactivate = true;
				if ($(event.target).attr("id") == "submit-next") {
					this.router.navigate(['review', this.form.value["candidateID"]]);
				} else {
					this.router.navigate(['poc']);
				}
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * Create a new POC test because the current one is invalid.
	 * The function saves the POC test to the database and refreshes the page.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	retake(event) {
		super.onSubmit(event);
		if (! this.form.valid) {
			if (! this.form.controls["firstPicture"].valid || ! this.form.controls["secondPicture"].valid) {
				$("#missing_input").removeClass('d-none');
			}
			return
		}

		jQuery('.success').removeClass('d-none'); // show a success notice while the POC test uploads
		this.apiService.createPOCTest(this.form.value["candidateID"],
			this.pictures["firstPicture"], this.form.value["firstPictureTimestamp"],
			this.pictures["secondPicture"], this.form.value["secondPictureTimestamp"],
			false
		).subscribe((response) => {
			jQuery('.success').addClass('d-none'); // hide the success notice to make space for errors or redirect
			if (response.result == "ok") {
				/*
				 * After saving the POC test, refresh the page.
				 */
				let candidateID = this.form.value['candidateID'];
				let path = this.router.createUrlTree(['poc', candidateID]).toString();
				let url = window.location.href.replace(this.router.url, path);
				window.location.href = url;
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/**
	 * When the candidate number changes, update the age.
	 * The calls are only made when the field loses focus, not upon input.
	 * This means that fewer requests will be made.
	 */
	onCandidateNumberChange() {
		let candidateID = this.form.value["candidateID"];
		if (this.withMDH) {
			this.mdhComponent.form.controls['candidateID'].setValue(candidateID);
		}
		this.loadCandidate(candidateID);
	}

	get diagnostic() { return this.form.get("firstPicture") }

}
