import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { candidateIDValidator } from '../classes/candidate';
import { ApiService } from '../services/api.service';
import { FormComponent } from '../form/form.component';
import { ActivatedRoute, Router } from '@angular/router';

export enum SelectionType {
	NONE,
	BLOOD,
	ENDOSCOPY,
	MDH,
	DIAGNOSTIC_PATHWAY
}

@Component({
  selector: 'app-candidate-selection',
  templateUrl: './candidate-selection.component.html',
  styleUrls: ['./candidate-selection.component.css']
})
export class CandidateSelectionComponent extends FormComponent implements OnInit {

	/**
     * The type of the selection screen.
     *
     * @type	{SelectionType}	type
     */
    public type: SelectionType = SelectionType.NONE;

  protected redirectURL: string = "";

  constructor(apiService: ApiService,
    protected formBuilder: FormBuilder,
    protected router: Router,
    protected activatedRoute: ActivatedRoute) {
      super(apiService);
  }

  /**
   * Check whether the test type is a blood test.
   * The function checks the type variable.
   *
   * @return {boolean} A boolean indicating whether the test type is a blood test.
   */
  isBloodTest() { return this.type == SelectionType.BLOOD; }

  /**
   * Check whether the test type is a endoscopy test.
   * The function checks the type variable.
   *
   * @return {boolean} A boolean indicating whether the test type is a endoscopy test.
   */
  isEndoscopyTest() { return this.type == SelectionType.ENDOSCOPY; }

  isMedicalHistoryQuestionnaire() { return this.type == SelectionType.MDH; }

  isDiagnosticPathway() { return this.type == SelectionType.DIAGNOSTIC_PATHWAY; }

  ngOnInit() {

    this.form = this.formBuilder.group({
      candidateID: ["", [
        Validators.required,
        candidateIDValidator(5)
      ]]
    });

    this.activatedRoute.params.subscribe(params => {
      this.redirectURL = params['redirect'];
    })

    super.ngOnInit(); // must come after the above initialization
  }

  /**
   * A function used to validate the request.
   * If the function validates, it should redirect.
   * Otherwise, it should show an error.
   *
   * @param {Event} event	The button click event.
   */
  validate(event: Event) {}

}
