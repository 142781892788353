import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormComponent } from '../form/form.component';

import { ApiService } from '../services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { candidateIDValidator } from '../classes/candidate';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-endoscopy',
	templateUrl: './endoscopy.component.html',
	styleUrls: ['./endoscopy.component.css']
})
export class EndoscopyComponent extends FormComponent implements OnInit {

	countries = [
		{ id: 'MLT', name: 'Malta' },
		{ id: 'ITA', name: 'Italy' }
	]

	private candidateID: string = "";
	private country: string = "";
	public testLocations: { [key: string]: any }[] = [];
	private testDate: Date = new Date(Date.now());
	private endoscopyType: string = "";
	private filename: string = "";
	mode: number = -1;

	constructor(apiService: ApiService,
		private formBuilder: FormBuilder,
		private router: Router,
		private route: ActivatedRoute,
		@Inject(LOCALE_ID) public locale: string) {
		super(apiService);
	}


	ngOnInit() {
		// read the location if stored
		this.country = localStorage.getItem('country') == null ? this.countries[0].id : localStorage.getItem('country');

		this.form = this.formBuilder.group({
			candidateID: [0, [
				Validators.required,
				candidateIDValidator(5)
			]],
			testLocation: ['', Validators.required],
			country: [this.country, Validators.required],
			testDate: ['', Validators.required],
			type: ['', Validators.required],
			biopsy_eval: [''],
			biopsy_samplesnum: [''],
			mucosal_deposit_eval: [''],
			mucosal_photo: ['']
		});

		this.loadTestLocations();

		// set up date stuff
		/*
		 * Wait for the date of test field to be rendered.
		 * Then, change the field to an actual date picker.
		 */
		(<any>$("#testDate")).datepicker({
			format: "dd/mm/yyyy",
			viewMode: "years",
			minViewMode: "days"
		});

		/*
		 * When the date of the test changes, update the date.
		 */
		(<any>$("#testDate")).on("changeDate", ((event) => {
			let d = event.date ? event.date : event.originalEvent.detail.date;
			let dstr = `${String(d.getDate()).padStart(2, "0")}/${String(d.getMonth() + 1).padStart(2, "0")}/${String(d.getFullYear()).padStart(2, "0")}`;
			this.form.controls['testDate'].setValue(dstr);
		}));

		/*
		 * Set the default date to be today.
		 */
		let today = new Date();
		let date = `${String(today.getDate()).padStart(2, "0")}/${String(today.getMonth() + 1).padStart(2, "0")}/${String(today.getFullYear()).padStart(2, "0")}`;
		(<any>$("#testDate")).datepicker("update", date).val(date);
		this.form.controls['testDate'].setValue(date);

		this.route.params.subscribe(params => {
			this.candidateID = params.candidateID ? params.candidateID : '';
			this.form.controls["candidateID"].setValue(this.candidateID);
		});

		super.ngOnInit();

		this.route.queryParams.subscribe(params => {
			// Defaults to -1 if no query param provided.
			this.mode = +params['mode'] || -1;
			if (this.mode == 2) {
				this.form.controls['type'].setValue("mucosal");
			} else if (this.mode == 1) {
				this.form.controls['type'].setValue("biopsy");
			}	
		});

	}


	/**
	   * Load the locations.
	   */
	loadTestLocations() {
		this.country = this.form.value['country'];
		if (this.country) {
			localStorage.setItem('country', this.country);
			this.testLocations = [];
			this.apiService.getLocations(this.country, 'hospital').subscribe((response) => {
				if (response.result == "ok") {
					this.testLocations = response.locations; // this is async so may happen slightly later
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					this.error = "s001";
				}
			});
		}
	}

	onTypeChange(event) {
		// remove all previous errors
		this.errorMessage = null;
		this.error = null;
		this.form.controls['testLocation'].setErrors(null);
	}

	onSubmit(event) {
		super.onSubmit(event);

		if (!this.form.valid) { return }
		
		// check a photo has been uploaded (only applies if mucosal)
		if (this.form.value['type'] == "mucosal" && !this.form.controls['mucosal_photo'].value) { // no photo uploaded
			this.error = "0037_0011"; // map to error
			this.errorMessage = "No mucosal photo selected!";
			jQuery('#mucosal_photo_btn').addClass('btn-danger').removeClass('btn-primary');
			return;
		}

		$('#pleasewait').removeClass('d-none'); // show the image uploading message
		
		let result = Object.assign({}, this.form.value);
		result.testDate = (<any>$("#testDate")).data().datepicker.date;
		this.apiService.endoscopyTest(result).subscribe((response) => {
			if (response.result == "ok") {
				jQuery('.success').removeClass('d-none');
				$('#pleasewait').addClass('d-none'); // hide the image uploading message, order is important
				setTimeout(() => {
					this.router.navigate(['dataEntry']);
				}, 3000);
			} else if (response.result == "error") {
				this.error = response.code;
				this.errorMessage = response.message;
			} else {
				this.error = "s001";
			}
		});
	}

	/*
 	  Read the uploaded image.

 	  The function is triggered whenever an uploaded image changes.
	  It reads the file that was chosen and provides it as the image source.

	  @param {Event}	event		The on-change event.
	 */
	readMucosalImage(event) {
		let reader = new FileReader();

		let element = event.target;
		let image = element.value;

		/*
		 * Once the reader loads the data, update the image source.
		 */
		let self = this;
		reader.onload = function (e) {
			let image_data = <string>reader.result
			self.form.controls['mucosal_photo'].setValue(event.target.files.item(0));
		}

		/*
		 * First, get the URL of the uploaded file.
		 * It only makes sense to read the picture if one was chosen.
		 */
		if (element.files.length) {
			let url = element.files[0];
			if (url) {
				reader.readAsDataURL(url);
				this.filename = element.files[0].name; // set the filename
			}

		}
	}

	/*
	 * When the upload button is clicked, click the hidden file input field.
	 */
	uploadMucosalPhoto() {
		jQuery("#mucosal_photo").click();
		jQuery('#mucosal_photo_btn').addClass('btn-primary').removeClass('btn-danger'); // remove any previous error
		this.error = null; // nullify any error (are we sure this is the best place?)
		this.errorMessage = null;
	}


	/*
	 * Check whether the user can perform the chosen endo test in the chosen location.
 	*/
	canPerformEndoscopyTest() {
		let testType = this.form.value['type'];
		let candidateID = this.form.value['candidateID'];

		if (testType == "mucosal") {

			this.apiService.getMucosalDeposits(candidateID).subscribe((response) => {
				/*
				 * Remove any existing error from the test location.
				 * It will be added next if need be.
				 */
				let errors = this.form.controls['testLocation'].errors;
				if (errors !== null) {
					delete errors['mucosal_test_already_performed'];
					delete errors['biopsy_test_already_performed'];
				}
				this.form.controls['testLocation'].setErrors(errors);

				if (response.result == 'ok') {
					response['exams'][candidateID].forEach((exam) => {
						if (exam.locationID == this.form.value['testLocation']) {
							this.form.controls['testLocation'].setErrors({ 'mucosal_test_already_performed': true });
							return;
						}
					});
				}
			})

		} else if (testType == "biopsy") {

			this.apiService.getBiopsy(candidateID).subscribe((response) => {
				/*
				 * Remove any existing error from the test location.
				 * It will be added next if need be.
				 */
				let errors = this.form.controls['testLocation'].errors;
				if (errors !== null) {
					delete errors['mucosal_test_already_performed'];
					delete errors['biopsy_test_already_performed'];
				}
				this.form.controls['testLocation'].setErrors(errors);

				if (response.result == 'ok') {
					response['exams'][candidateID].forEach((exam) => {
						if (exam.locationID == this.form.value['testLocation']) {
							this.form.controls['testLocation'].setErrors({ 'biopsy_test_already_performed': true });
							return;
						}
					});
				}
			})

		}
	}

}
