import { Component, OnInit } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.css']
})
export class MainMenuComponent implements OnInit {

    /**
     * Load the authentication service when creating the component.
     *
     * @param {AuthService}		authService		The service that checks whether a user can access certain pages.
     */
    constructor(public authService: AuthService) { }

	ngOnInit() {
	}

}
