
declare var $: any;

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService, Question } from '../services/api.service';
import { ModalService } from '../services/modal.service';

import { Candidate, candidateIDValidator } from "../classes/candidate";
import { QuestType } from '../classes/quest-type';
import { FormComponent } from '../form/form.component';

function parseDate(date): Date {
	const date_components = date.split('/');
	return new Date(parseInt(date_components[2]), parseInt(date_components[1]) - 1, parseInt(date_components[0]));
}

/**
 * Validate the search date range.
 * The range end should be after the range start.
 *
 * @return {FormControl}	start The range start date.
 */
function dateEndRangeValidator(start): ValidatorFn {
	return (control: AbstractControl): {[key: string]: any} | null => {
		if (control.value == null || start.value == null) { return null; }
		const valid = parseDate(control.value) >= parseDate(start.value);
		return valid ? null : { "invalidEndRange": {value: control.value} };
	};
}

/**
 * Validate the search date range.
 * The range start should be before the range end.
 *
 * @return {FormControl}	end The range end date.
 */
function dateStartRangeValidator(end): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
		if (control.value == null || end.value == null) { return null; }
		const valid = parseDate(control.value) <= parseDate(end.value);
		return valid ? null : { "invalidStartRange": {value: control.value} };
	};
}

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css']
})
export class SearchComponent extends FormComponent implements OnInit {

	/**
	 * The current test filter.
	 */
	testFilter: string = 'all';

	/**
	 * The Math library.
	 */
	Math: any;

	/**
	 * The questions in the medical history questionnaire.
	 *
	 * @access public
	 * @type {Question[]} questions
	 */
	public questions : Question[];

	/**
	 * A list of locations from which the user can pick in the MHQ.
	 *
	 * @access public
	 **
	 * @type {string[]} locations
	 */
	public locations: { [key: string]: any }[] = [];

	/**
	 * A list of towns from which the user can pick in the main search parameters.
	 *
	 * @access public
	 **
	 * @type {string[]} towns
	 */
	public towns: { [key: string]: any }[] = [];

	/**
	 * A list of pathways.
	 *
	 * @access public
	 **
	 * @type {object} pathways
	 */
	public pathways: { } = [];

	/**
	 * The blood form with which the user interacts.
	 *
	 * @type {FormGroup} blood
	 */
	blood: FormGroup;

	/**
	 * The endoscopy form with which the user interacts.
	 *
	 * @type {FormGroup} endoscopy
	 */
	endoscopy: FormGroup;

	/**
	 * The questionnaire form with which the user interacts.
	 *
	 * @type {FormGroup} questionnaire
	 */
	questionnaire: FormGroup;

	/**
	 * A boolean indicating whether there is an ongoing search.
	 *
	 * @type {FormGroup} searching
	 */
	searching: boolean = false;

	/**
	 * The API service is used to fetch
	 *
	 * @param {ApiService}		apiService		The service that connects to the API.
	 * @param {FormBuilder}		formBuilder		The form builder service.
	 * @param {ModalService}	modalService	A service used to show modals.
	 * @param {Router}				router			The router service.
	 */
	constructor(
		apiService: ApiService,
		private formBuilder: FormBuilder,
		public modalService: ModalService,
		private router: Router,
	) {
		super(apiService);

		/*
		 * Load the questionnaire questions.
		 */
		this.questions = this.apiService.getMedicalQuestionnaire(QuestType.ALL);
	}

	/**
	 * When the component loads, get a list of tests that need reviewing.
	 */
	ngOnInit() {
		this.createFormGroups(this.formBuilder);
		super.ngOnInit();
		this.prepareFields();
		this.Math = Math;
		this.setupTable();
		this.loadPathways();
	}

	/**
	 * Create a form group for the candidate model.
	 * This function creates separate form groups for the general test filters, the questionnaire filters, the blood filters and the endoscopy filters.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroups(formBuilder: FormBuilder) {
		let form = formBuilder.group({
			candidateID: ['', [ candidateIDValidator(5) ]],
			candidateCountry: [''],
			candidateTownID: [''],
			dateFrom: [], dateTo: [],
			hasPoct: [], hasQuest: [],
			poctRes: [],
			poctRetake: [],
			waitingForTest: [],
			diagnosis: [],
			pathwayID: []
		});
		this.form = form;

		this.questionnaire = formBuilder.group({
			questCountry: [''], questLocation: [''],
			parentswithdisease: [''], questYeses: ['', [ Validators.min(0), Validators.max(30), Validators.pattern('[0-9]{1,2}') ]]
		});
		this.questions.forEach((question) => {
			this.questionnaire.addControl(question.paramName, new FormControl('all'));
		});

		this.blood = formBuilder.group({
			bloodCountry: [''], bloodLocation: [''],
			igatotFrom: [], igatotTo: [],
			ema: [],
			ttgigaFrom: [], ttgigaTo: [],
			ttgiggFrom: [], ttgiggTo: [],
			dpgiggFrom: [], dpgiggTo: [],
			aacFrom: [], aacTo: []
		});

		this.endoscopy = formBuilder.group({
			endoscopyCountry: [''], endoscopyLocation: [''],
			mucosalEval: [''],
			biopsyEvaluation0: [], biopsyEvaluation1: [], biopsyEvaluation2: [],
			biopsyEvaluation3a: [], biopsyEvaluation3b: [], biopsyEvaluation3c: [],
		});
	}

	/**
	 * Clear the search filters.
	 * @param {any}	e	The button press event.
	 */
	clearFilters(e) {
		this.createFormGroups(this.formBuilder);
	}

	/**
	 * Prepare the fields for input.
	 * The function loads the date fields.
	 */
	prepareFields() {
		$(['#dateFrom', '#dateTo']).each((index, field) => {
			(<any> $(field)).datepicker( {
				format: "dd/mm/yyyy",
				viewMode: "years",
				minViewMode: "days"
			});
			/*
			 * When the date of the test changes, update the date.
			 */
			(<any> $(field)).on("changeDate", ((event) => {
				const fieldName = field.substring(1);
				if (event.date) {
					this.form.controls[fieldName].setValue(`${String(event.date.getDate()).padStart(2, '0')}/${String(event.date.getMonth() + 1).padStart(2, '0')}/${event.date.getFullYear()}`);
				} else {
					this.form.controls[fieldName].setValue(event.originalEvent.detail.date);
				}

				this.form.controls['dateFrom'].updateValueAndValidity({ onlySelf: false, emitEvent: true });
				this.form.controls['dateTo'].updateValueAndValidity({ onlySelf: false, emitEvent: true });
			}));
		});

		/*
		 * Add the validators to the date range end and start.
		 */
		this.form.controls['dateFrom'].setValidators([ dateStartRangeValidator(this.form.controls['dateTo']) ]);
		this.form.controls['dateFrom'].updateValueAndValidity();
		this.form.controls['dateTo'].setValidators([ dateEndRangeValidator(this.form.controls['dateFrom']) ]);
		this.form.controls['dateTo'].updateValueAndValidity();
	}

	/**
	 * Set up the search table.
	 */
	setupTable() {
		let table = $("#datatable").DataTable({
			dom: 'Bfrtip',
			stateSave: true,
			columnDefs: [{
				targets: 'hidden',
				visible: false
			}],
			buttons: [{
				extend: 'colvis',
				collectionLayout: 'fixed two-column',
				columns: ':not(.noVis)'
			}]
		});
	}

	/**
	 * Create an iterable range.
	 * This function is used instead of a for loop in the template.
	 *
	 * @param {number}	n	The end range, exclusive.
	 * @return {number[]}	An array of numbers up to and excluding `n`.
	 */
	createRange(n: number) : number[] {
		let range : number[] = []
		for (let i = 0; i < n; i++) {
			range.push(i);
		}
		return range;
	}

	/**
	 * Load the locations.
	 *
	 * @param {any}	e	The country change event.
	 * @param {string|null}	locationType	The type of locations to retrieve.
	 *										The type can be 'hospital' or 'school'.
	 *										If it is not given, all locations are retrieved.
	 */
	loadLocations(e: any, locationType: string = null) {
		let country = e.target.value;
		if (country) {
			this.apiService.getLocations(country, locationType).subscribe((response) => {
				if (response.result == "ok") {
					this.locations = response.locations;
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					this.error = "s001";
				}
			});
		}
	}

	/**
	 * Load the locations.
	 *
	 * @param {any}	e	The country change event.
	 */
	loadTowns(e: any) {
		let country = e.target.value;
		if (country) {
			this.apiService.getTowns(country).subscribe((response) => {
				if (response.result == "ok") {
					this.towns = response.towns;
				} else if (response.result == "error") {
					this.error = response.code;
				} else {
					this.error = "s001";
				}
			});
		}
	}

	/**
	 * Load the different diagnostic pathways.
	 */
	loadPathways() {
		this.apiService.getPathways().subscribe((response) => {
			if (response.result == "ok") {
				let pathways = { };
				$(response.diagnosticpathways).each(function(i, e) {
					pathways[e.pathwayID] = e.pwyDescr;
				});
				this.pathways = pathways;
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

	/*
	 * Check whether any field in the given form group is filled.
	 * The function skips fields that are marked as 'all' as if they are not filled.
	 */
	anyFilled(group) {
		let filled = false;
		let values = Object.assign({}, group.value);
		Object.keys(values).forEach((key) => {
			if (values[key] && values[key] != 'all') {
				filled = true;
			}
		});
		return filled;
	}

	/**
	 * Load the tests to display in the table.
	 * The tests are loaded directly into the `tests` variable.
	 */
	loadTests($e) {
		this.searching = true;
		let table = $("#datatable").DataTable();
		table.clear().draw();
		$('#table').addClass('d-none')

		let result = Object.assign({}, this.form.value);
		let blood = Object.assign({}, this.blood.value);
		let endoscopy = Object.assign({}, this.endoscopy.value);
		let questionnaire = Object.assign({}, this.questionnaire.value);
		this.apiService.search(result, blood, endoscopy, questionnaire).subscribe((response) => {
			if (response.result == "ok") {
				let rows = [ ];
				$.each(response.candidates, (i, candidate) => {
					let path = this.router.createUrlTree(['pathway', candidate.candidateID]).toString();
					let url = window.location.href.replace(this.router.url, path);

					/*
					 * Calculate the age.
					 */
					let age = 0;
					if (candidate.candidateBirthdate) {
						const date = candidate.candidateBirthdate.split('-');
						let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
						age = Candidate.calculateAge(dateOfBirth);
					}
					let years = age ? Math.floor(age) : 0;
					let months = age ? Math.floor((age % 1) * 12.) : 0;

					let status = candidate.Status ? `${ this.pathways[candidate.pathwayID] } (${ candidate.Status })` : this.pathways[candidate.pathwayID];
					let diagnoses = { "Y": "Celiac", "N": "Non-Celiac", "R": "Refused" };
					let diagnosis = 'No diagnosis';
					if (candidate.diagnosis !== "None") {
						diagnosis = diagnoses[candidate.diagnosis];
					}

					/*
					 * Add a new row to the table.
					 */
 					rows.push([
 						candidate.candidateID,
						candidate.candidateGender,
						years,
						status,
						diagnosis,
						candidate.hasMHQ == 'Y' ? 'Yes' : 'No',
						candidate.hasPoCT == 'Y' ? 'Yes' : 'No',
						candidate.enabledforblood == 'Y' ? 'Yes' : 'No',
						candidate.hasOneBlood == 'Y' ? 'Yes' : 'No',
						candidate.enabledforendo == 'Y' ? 'Yes' : 'No',
						candidate.hasOneEndo == 'Y' ? 'Yes' : 'No',
 						candidate.candidateTown.town.replace(/[A-Z]/g, ' $&').trim(),
 						candidate.candidateEthnicity,
						// `${ years } ${ years == 1 ? 'year' : 'years' } ${ months } ${ months == 1 ? 'month' : 'months' }`,
						`<a href='${url}'" target='_blank' class="btn btn-primary" ><span class="search-icon"></span></a>`
 					]);
				});
				// render at the end
				$('#table').removeClass('d-none')
				table.rows.add(rows).draw();
			}
			this.searching = false;
		});
	}
}
