import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ApiService, StatisticsResponse } from '../services/api.service';
import { FormComponent } from '../form/form.component';

@Component({
  selector: 'dev-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent extends FormComponent implements OnInit {

  /**
   * The loaded statistics.
   *
   * @access private
   *
   * @type {StatisticsResponse} statistics
   */
  public statistics: StatisticsResponse;

  /**
   * Inject the API service into the statistics.
   *
   * @param {ApiService}		apiService		The service that connects to the API.
   * @param {FormBuilder}		formBuilder		The form builder service.
   */
  constructor(apiService: ApiService,
              private formBuilder: FormBuilder) {
      super(apiService);
  }

  /**
   * Load the statistics on initialization.
   */
  ngOnInit(): void {
      this.form = this.createFormGroup(this.formBuilder);
      this.loadStatistics();
  }

  /**
   * Create a form group for the statistics.
   *
   * @param {FormBuilder}	formBuilder	The injected form builder.
   *
   * @return {FormGroup} The form group for the model.
   */
  createFormGroup(formBuilder: FormBuilder) {
      return formBuilder.group({
          country: [ localStorage.getItem('statistics-country') || '' ],
          project: [ localStorage.getItem('statistics-project') || '' ],
      });
  }

  /**
   * Load the statistics.
   */
  loadStatistics(): void {
      let country = this.form.value['country'];
      let project = this.form.value['project'];
      localStorage.setItem('statistics-country', country);
      localStorage.setItem('statistics-project', project);
      console.log(country);
      console.log(project);
      this.apiService.getStatistics(country, project).subscribe((response) => {
        this.statistics = response;
      });
  }

}
