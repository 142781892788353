import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	/**
	 * When the component is initialized, define the navbar pages.
	 *
     * @param {ApiService}		apiService		The service used to connect with the API.
     * @param {AuthService}		authService		The service that checks whether a user can access certain pages.
	 * @param {Router}	router	The router service.
	 */
	constructor(private apiService: ApiService,
                public authService: AuthService,
				public router: Router) { }

	ngOnInit() {
		this.apiService.ping().subscribe((response) => {
			if (response.status != 200) {
				jQuery('#server-connection').removeClass('d-none');
			}
		});
	}

	/**
	 * A function to check if the application is in development mode.
	 *
	 * @return {bool} A boolean indicating if the application is in development mode.
	 */
	is_dev() {
		return ! window.location.origin.match(/https?:\/\/app\.itama.+/);
	}

	/**
	 * Log out the user.
	 *
	 * The function undoes what the login function does.
	 * First, it calls the API to remove the token from the backend.
	 * Then, it removes the token and the operator ID.
	 * Finally, it redirects the user to the login screen.
	 */
	logout() {
		this.apiService.logout().subscribe((response) => {
			if (response.result == "ok") {
				this.authService.logout();
				this.router.navigate(['']);
			}
		});
	}

}
