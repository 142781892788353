import { Component, OnInit,
 		 Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from '../services/api.service';
import { FormComponent } from '../form/form.component';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends FormComponent implements OnInit {

	/**
	 * On initialization, create the form.
	 *
	 * @param {ApiService}		apiService		The service that connects to the API.
	 * @param {FormBuilder}		formBuilder		The form builder service.
	 * @param {Router}			router		The router service.
	 */
	constructor(
		apiService: ApiService,
		private formBuilder: FormBuilder,
		public router: Router,
		@Inject(LOCALE_ID) public locale: string
	) {
		super(apiService);
	}

	/**
	 * On initialization, create the form.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		super.ngOnInit();
	}

	/**
	 * Create a form group for the form.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({
			username: ['', Validators.required],
		});
	}

	/**
	 * Get the form's values and send them to the API.
	 *
	 * @param {MouseEvent}	event	The click event.
	 */
	resetPassword(event) {
		super.onSubmit(event);
		const result = Object.assign({}, this.form.value);

		this.apiService.resetPassword(result.username, this.locale).subscribe((response) => {
			if (response.result == "ok") {
				jQuery('.success').removeClass('d-none');
			} else if (response.result == "error") {
				this.error = response.code;
			} else {
				this.error = "s001";
			}
		});
	}

}
