import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode, Inject, LOCALE_ID } from '@angular/core';

import { of, Observable, throwError } from 'rxjs';

import { Candidate } from '../classes/candidate';
import { User } from '../classes/user';
import { QuestType } from '../classes/quest-type';
import { Questionnaire } from '../classes/questionnaire';

/***********************************
 *           API Responses         *
 ***********************************/

export interface ApiResponse {
	/**
	* A basic response from the API.
	*
	* @property	{string}	code	The API response code.
	* @property	{string}	result	The API result.
	* @property {string}	message	The API's message.
	*/

	code: string,
	result: string;
	message: string;
}

export interface POCTestDetailsResponse extends ApiResponse {
	/**
	* A response that includes the POC test details.
	*
	* @property	{string}	candidateID		The candidate's ID.
	* @property {string}	poctPhoto1		The first POC test image's name.
	* @property {string}	poctPhoto1time	The date-time string when the first image was uploaded.
	* @property {string}	poctPhoto2		The second POC test image's name.
	* @property {string}	poctPhoto2time	The date-time string when the second image was uploaded.
	*/

	candidateID: string;
	poctPhoto1: string;
	poctPhoto1time: string;
	poctPhoto2: string;
	poctPhoto2time: string;
}

export interface CandidateDetailsResponse extends ApiResponse {
	/**
	* A response that includes the candidate test details.
	*
	* @property	{string}	candidateID		The candidate's ID.
	* @property	{string}	candidateRegion		The candidate's region.
	* @property {string}	candidateEthnicity	The candidate's ethnicity.
	* @property {string}	candidateGender		The candidate's gender - one character, either 'M' or 'F'.
	* @property {string}	candidateBirthdate	The candidate's date of birth, formatted as YYYY-MM-DD.
	* @property {string}	pathwayID			The ID of the candidate's pathway.
	*/

	candidateID: string;
	candidateTown: { [key: string]: any };
	candidateEthnicity: string;
	candidateGender: string;
	candidateBirthdate: string;
	candidateCodfis: string;
	pathwayID: string;
}

export interface MedicalHistoryQuestionnaire {
	questDatefilledin: string;
	questLocation: string;
	questVitiligo: string;
	questLastdatetime: string;
	questGrowthprobl: string;
	questTired: string;
	questAlopecia: string;
	parents: string[];
}

export interface MedicalHistoryQuestionnaireResponse extends ApiResponse {
	questionnaires: { [candidateID: string]: MedicalHistoryQuestionnaire };
}

export interface LoginResponse extends ApiResponse {
	token: string;
	operatorID: string;
	exp: string;
}

export interface OperatorsResponse extends ApiResponse {
	operators: Operator[];
}

export interface Operator {
	opUsername: string;
	opEmail: string;
	opFreeField2: string;
	opRole: string;
	opTel: string;
	opFreeField1: string;
	operatorID: string;
	opAffiliation: string;
	opName: string;
	opSurname: string;
	opFreeField3: string;
}

export interface TownsResponse extends ApiResponse {
	code: string;
	result: string;
	towns: { [key: string]: any }[];
}

export interface LocationsResponse extends ApiResponse {
	code: string;
	result: string;
	locations: { [key: string]: any }[];
}

export interface DevicesResponse extends ApiResponse {
	code: string;
	result: string;
	devices: { [key: string]: any }[];
}

export interface CandidatePathwayResponse extends ApiResponse {
	code: string;
	result: string;
	check: boolean;
}

export interface PathwaysResponse extends ApiResponse {
	code: string;
	result: string;
	diagnosticpathways: { [key: string]: any }[];
}

export interface BloodTestResponse extends ApiResponse {
	code: string;
	result: string;
	exams: { [key: string]: any }[];
}

export interface SearchResponse extends ApiResponse {
	code: string;
	result: string;
	candidates: { [key: string]: any }[];
}

export interface StatisticsResponse extends ApiResponse {
	candidates: string;
	questionnaires: string;
	pocts: string;
	positives: string;
	'confirmed positives': string;
	negatives: string;
	'confirmed negatives': string;
	invalids: string;
	confirmedinvalids: string;
	fiveyesesandmore: string;
	bloodtests: string;
	biopsies: string;
	mucosal: string;
	"Waiting for blood tests": string;
	"Waiting for endoscopy tests": string;
	"PoCTS with one judgement": string;
}

export interface CheckDiagnosisResponse extends ApiResponse {
	Diagnosis: string;
	CandidateID: string;
}

export interface CandidateStepResponse extends ApiResponse {
	Current_step: number;
	CandidateID: string;
}


export interface CandidateStatusResponse extends ApiResponse {
	pathwayID: string;
	hasPoCT: string;
	hasMHQ: string;
	enabledForBlood: string;
	hasOneBlood: string;
	enabledForEndo: string;
	hasOneEndo: string;
	diagnosis: string;
	quest2Blood: Operator;
	blood2Endo: Operator;
	diagnoser: Operator;
}


export interface TestResponse extends ApiResponse {
	results: { [key: string]: any }[];
}

/***********************************
 *     Temporary API Responses     *
 ***********************************/

export interface Relative {
	readonly id: number;
	readonly name: string;
	readonly value: string;
}

export interface Question {
	readonly id: number,
	readonly questionText: string,
	readonly options: string[],
	readonly paramName: string,
	readonly defaultAns: string,
	readonly values: string[]
}

/**
 * The API service handles all requests to the backend.
 */
@Injectable({
	providedIn: 'root'
})
export class ApiService {

	/**
	 * The URL to which all calls will be made.
	 *
	 * The URL changes according to the application's mode.
	 * If it is in development mode, calls go through the `/api` endpoint.
	 * The `/api` endpoint is a proxy for the backend.
	 * If the application is in production mode, then all calls go directly to the backend.
	 */
	apiURL: string = isDevMode() ? '/api' : 'https://dbitama.unipa.it:17494'; // using a proxy setup which forwards to outward IP address

	constructor(private httpClient: HttpClient, @Inject(LOCALE_ID) public locale: string) {
		let domain = window.location.origin;
		if (domain.match(/https?:\/\/app\.itama.+/)) {
			this.apiURL = 'https://dbitama.unipa.it:17494';
		} else if (domain.match(/https?:\/\/localhost.+/)) {
			this.apiURL = 'https://dbitama.unipa.it:17494';
		} else {
			this.apiURL = 'https://dbitama.unipa.it:17494';
		}
	}

	/**
	 * Ping the server.
	 *
	 * @return {Observable}	The API's response.
	 */
	public ping() {
		let endpoint = "/";

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.get(url, {observe: 'response', responseType: 'text'});
	}

	public login(u: User) {
		// API Endpoint for login: https://147.163.114.8:17494/auth/login?user=[USERNAME]&pwd=[PASSWORD]
		// return this.httpClient.get<LoginResponse>(`${this.apiURL}/auth/login?user=${u.email}&pwd=${u.password}`);

		const postBody = new FormData();
		postBody.append("user", u.email);
		postBody.append("pwd", u.password);

		return this.httpClient.post<LoginResponse>(`${this.apiURL}/auth/login`, postBody);
	}

	/**
	 * Get the currently-logged in operator details.
	 *
	 * @return Observable<ApiResponse>	The API response.
	 */
	public getOperator(): Observable<OperatorsResponse> {
		let endpoint = "/operator";

		let paramString = this.getParamString({
			'token': localStorage.getItem('token'),
			'operatorID': localStorage.getItem('operatorID')
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<OperatorsResponse>(url);
	}

	/**
	 * Log out the user from the backend.
	 *
	 * @return Observable<ApiResponse>	An array of test objects.
	 */
	public logout() {
		let endpoint = "/auth/logout";
		let postBody: FormData = new FormData();

		postBody.append("token", localStorage.getItem("token"));

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	/***********************************
	 *          Password reset         *
	 ***********************************/

	/**
	 * Change the user's password.
	 *
	 * @param {string}	username	The user's email.
	 * @param {string}	locale		The locale.
	 *
	 * @return Observable<ApiResponse>	The API response.
	 */
	public resetPassword(username: string, locale: string) {
		let endpoint = "/auth/resetpassword";
		let postBody: FormData = new FormData();

		postBody.append("username", username);

		if (locale.indexOf('-') > 0) {
			locale = locale.substr(0, locale.indexOf('-'));
		}
		postBody.append("lang", locale);

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	/**
	 * Change the user's password.
	 *
	 * @param {string}	username	The user's email.
	 * @param {string}	resetToken	The token used to validate the request.
	 * @param {string}	newPassword	The user's new password.
	 *
	 * @return Observable<ApiResponse>	The API response.
	 */
	public newPassword(username: string, resetToken: string, newPassword: string) {
		let endpoint = "/auth/newpassword";

		let paramString = this.getParamString({
			'username': username,
			'resetToken': resetToken,
			'newPassword': newPassword
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}

	/***********************************
	 *        Candidate details        *
	 ***********************************/

	/**
	 * Create or update a candidate in the backend.
	 *
	 * @param {Candidate}	candidate	The candidate to modify to the backend.
	 * @param {boolean}		edit		A boolean indicating whether to update (PUT) an existing candidate or create (POST) a new one.
	 */
	public candidate(candidate: Candidate, edit: boolean) {
		let endpoint = "/candidate";

		const postBody = new FormData();
		postBody.append("candidateID", candidate.candidateID);
		postBody.append("candidateTownID", candidate.town);
		postBody.append("candidateBirthdate", `${candidate.dateOfBirth.getFullYear()}-${String(candidate.dateOfBirth.getMonth() + 1).padStart(2, '0')}-${String(candidate.dateOfBirth.getDate()).padStart(2, '0')}`);
		postBody.append("candidateGender", candidate.gender);
		postBody.append("candidateEthnicity", candidate.ethnicity);
		postBody.append("pathwayID", candidate.pathwayID);
		postBody.append("token", localStorage.getItem("token"));

		let url = `${this.apiURL}${endpoint}`;

		if (edit) {
			return this.httpClient.put<ApiResponse>(url, postBody);
		} else {
			return this.httpClient.post<ApiResponse>(url, postBody);
		}
	}

	/**
	 * Get the candidate details by their unique identifier.
	 *
	 * @param {string}	candidateID	The candidate's unique ID.
	 *
	 * @return {Observable<CandidateDetailsResponse>}	The API's response.
	 */
	public getCandidateDetails(candidateID: string) {
		let endpoint = "/candidate";

		let paramString = this.getParamString({
			'candidateID': candidateID,
			'token': localStorage.getItem("token")
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<CandidateDetailsResponse>(url);
	}

	/**
	 * Get the towns based on the country of the test.
	 *
	 * @param {string}	country	The country whose towns will be required.
	 *
	 * @return {Observable<TownsResponse>}	The API's response.
	 */
	public getTowns(country: string): Observable<TownsResponse> {
		let endpoint = "/towns";

		let paramString = this.getParamString({
			'country': country,
			'token': localStorage.getItem("token")
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<TownsResponse>(url);
	}

	/**
	 * Get the pathways.
	 *
	 * @return {Observable<PathwaysResponse>}	The API's response.
	 */
	public getPathways(): Observable<PathwaysResponse> {
		let endpoint = "/diagnosticpathways";

		let paramString = this.getParamString({
			'token': localStorage.getItem("token")
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<PathwaysResponse>(url);
	}

	/***********************************
	 *             POC test            *
	 ***********************************/

	/**
	 * Upload the POC test details.
	 *
	 * @param {number}	patID			The candidate's unique number.
	 * @param {File}	poctPhoto1		The data of the first photo.
	 * @param {number}	poctPhoto1time	The timestamp when the first photo was uploaded.
	 * @param {File}	poctPhoto2		The data of the second photo.
	 * @param {number}	poctPhoto2time	The timestamp when the second photo was uploaded.
	 * @param {boolean}	valid			A boolean indicating whether the POC test is valid.
	 */
	public createPOCTest(patID: number,
		poctPhoto1: File, poctPhoto1time: number,
		poctPhoto2: File, poctPhoto2time: number,
		valid: boolean
	) {
		let endpoint = "/poct";

		/*
		 * Javascript date objects take timestamps as milliseconds.
		 */
		let poctPhoto1Date = new Date(poctPhoto1time * 1000);
		let poctPhoto2Date = new Date(poctPhoto2time * 1000);

		let postBody: FormData = new FormData();
		postBody.append("candidateID", String(patID));
		if (poctPhoto1) {
			postBody.append("poctPhoto1", poctPhoto1, poctPhoto1.name);
		} else {
			postBody.append("poctPhoto1", new Blob(), 'empty.jpg'); // TODO: Remove the else clause when the schema is updated to make pictures optional.
		}
		postBody.append("poctPhoto1time",
			`${poctPhoto1Date.getFullYear()}-${String(poctPhoto1Date.getMonth() + 1).padStart(2, '0')}-${String(poctPhoto1Date.getDate()).padStart(2, '0')} ${String(poctPhoto1Date.getHours()).padStart(2, '0')}-${String(poctPhoto1Date.getMinutes()).padStart(2, '0')}-${String(poctPhoto1Date.getSeconds()).padStart(2, '0')}`
		);
		if (poctPhoto2) {
			postBody.append("poctPhoto2", poctPhoto2, poctPhoto2.name);
		} else {
			postBody.append("poctPhoto2", new Blob(), 'empty.jpg'); // TODO: Remove the else clause when the schema is updated to make pictures optional.
		}
		postBody.append("poctPhoto2time",
			`${poctPhoto2Date.getFullYear()}-${String(poctPhoto2Date.getMonth() + 1).padStart(2, '0')}-${String(poctPhoto2Date.getDate()).padStart(2, '0')} ${String(poctPhoto2Date.getHours()).padStart(2, '0')}-${String(poctPhoto2Date.getMinutes()).padStart(2, '0')}-${String(poctPhoto2Date.getSeconds()).padStart(2, '0')}`
		);
		postBody.append("valid", valid ? 'Y' : 'N');
		postBody.append("token", localStorage.getItem("token"));

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	/***********************************
	 *         Medical history         *
	 ***********************************/

	public getRelativesWithDiseaseForMedicalQuestionnaire(): Relative[] {
		// Possible values:
		// 	father, mother, paternal_grandfather, paternal_grandmother, maternal_grandfather, maternal_grandmother
		switch (this.locale) {
			case "it":
				return [
					{ id: 1, name: 'Padre', value: 'father' },
					{ id: 2, name: 'Madre', value: 'mother' },
					{ id: 3, name: 'Nonno paterno', value: 'paternal_grandfather' },
					{ id: 4, name: 'Nonna paterna', value: 'paternal_grandmother' },
					{ id: 5, name: 'Nonno materno', value: 'maternal_grandfather' },
					{ id: 6, name: 'Nonna materno', value: 'maternal_grandmother' },
					{ id: 7, name: 'Fratello / Sorella', value: 'sibling' },
					{ id: 8, name: 'Figlio / Figlia', value: 'son_daughter' },  // for IT only
					{ id: 8, name: 'Altro', value: 'other' },
				];
			case "en-US":
			case "en-GB":
			default:
				return [
					{ id: 1, name: 'Father', value: 'father' },
					{ id: 2, name: 'Mother', value: 'mother' },
					{ id: 3, name: 'Paternal Grandfather', value: 'paternal_grandfather' },
					{ id: 4, name: 'Paternal Grandmother', value: 'paternal_grandmother' },
					{ id: 5, name: 'Maternal Grandfather', value: 'maternal_grandfather' },
					{ id: 6, name: 'Maternal Grandmother', value: 'maternal_grandmother' },
					{ id: 7, name: 'Sister / Brother', value: 'sibling' },
					{ id: 8, name: 'Other', value: 'other' },
				];
		}
	}

	public getMedicalQuestionnaire(questType: QuestType): Question[] {
		switch (this.locale) {
			case "it":
				return Questionnaire.ITALIAN_QUESTIONS;
			case "en-US":
			case "en-GB":
			default:
				if (questType == QuestType.KIDS) {
					return Questionnaire.MALTESE_QUESTIONS_KIDS;
				} else if (questType == QuestType.ADULTS) {
					return Questionnaire.MALTESE_QUESTIONS_ADULTS;
				} else if (questType == QuestType.ALL) {
					let questions = Questionnaire.MALTESE_QUESTIONS_KIDS;
					questions = questions.concat(Questionnaire.MALTESE_QUESTIONS_ADULTS);

					/*
					 * Return the unique questions.
					 */
					let collected = {};
					return questions.filter((question) => {
						if (collected[question.id]) return false;

						collected[question.id] = true;
						return true;
					});
				} else {
					new Error(`Unknown Quest type: ${questType}`);
				}
		}
	}

	public getCandidateMedicalHistory(candidateID: string): Observable<MedicalHistoryQuestionnaireResponse> {
		let endpoint = "/questionnaire";
		let paramString = this.getParamString({
			'candidateID': candidateID,
			'token': localStorage.getItem("token")
		})
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		let resp = this.httpClient.get<MedicalHistoryQuestionnaireResponse>(url);
		return resp;
	}


	public deleteCandidateMedicalHistory(candidateID: string): Observable<ApiResponse> {
		let endpoint = "/questionnaire";
		const httpParams = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {
				'candidateID': candidateID,
				'token': localStorage.getItem("token")
			}
		}
		let url = `${this.apiURL}${endpoint}`;
		let resp = this.httpClient.delete(url);
		return this.httpClient.delete<ApiResponse>(url, httpParams);
	}

	public addCandidateMedicalHistory(candidateID: string, questionnaireDate: Date, region: string, relatives: string, questions: [string, string][], update: boolean = false): Observable<ApiResponse> {
		let endpoint = "/questionnaire";

		const postBody = new FormData();
		postBody.append("token", localStorage.getItem("token"));
		postBody.append("candidateID", candidateID);
		postBody.append("questDatefilledin", `${questionnaireDate.getFullYear()}-${String(questionnaireDate.getMonth() + 1).padStart(2, '0')}-${String(questionnaireDate.getDate()).padStart(2, '0')}`);
		postBody.append("operatorID", localStorage.getItem("operatorID"));
		postBody.append("questLocation", region);
		postBody.append("questClass", "0");
		postBody.append("questParentswdisease", (relatives === '') ? 'none' : relatives); // as per spec.

		questions.forEach(q => {
			postBody.append(q[0], q[1]);
		});
		let url = `${this.apiURL}${endpoint}`;
		if (update) {
			return this.httpClient.put<ApiResponse>(url, postBody);
		} else {
			return this.httpClient.post<ApiResponse>(url, postBody);
		}
	}

	public getCandidatesWithFivePositiveQuestionsAndPOCTDone(pathwayID: string): Observable<ApiResponse> {
		let endpoint = "/candidateswith5yandpoctdone";
		let params = {
			'token': localStorage.getItem("token"),
			'pathwayID': pathwayID
		}
		let paramString = this.getParamString(params);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}

	public getCandidatesWithFivePositiveQuestionsAndPOCTNegative(pathwayID: string): Observable<ApiResponse> {
		let endpoint = "/candidateswith5yandpoctneg";
		let params = {
			'token': localStorage.getItem("token"),
			'pathwayID': pathwayID
		}
		let paramString = this.getParamString(params);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}


	public getCandidatesWithPositivePOCTAndMHQComplete(pathwayID: string): Observable<ApiResponse> {
		let endpoint = "/confirmedpospoctandmhqdone";
		let params = {
			'token': localStorage.getItem("token"),
			'pathwayID': pathwayID
		}
		let paramString = this.getParamString(params);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}

	public getCandidatesWithNegativePOCTAndNegativeMDH(pathwayID: string): Observable<ApiResponse> {
		let endpoint = "/allnegativecandidates";
		let params = {
			'token': localStorage.getItem("token"),
			'pathwayID': pathwayID
		}
		let paramString = this.getParamString(params);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}

	public getCandidatesWithHighTTGIgA(pathwayID: string): Observable<ApiResponse> {
		let endpoint = "/highttgiga";
		let params = {
			'token': localStorage.getItem("token"),
			'pathwayID': pathwayID
		}
		let paramString = this.getParamString(params);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}


	public getCandidatesWithLowIgATot(pathwayID: string): Observable<ApiResponse> {
		let endpoint = "/lowigatot";
		let params = {
			'token': localStorage.getItem("token"),
			'pathwayID': pathwayID
		}
		let paramString = this.getParamString(params);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}





	/***********************************
	 *        POC test listing         *
	 ***********************************/

	/**
	 * Get a list of tests that fit the given rules.
	 *
	 * Currently, the system supports the following test types:
	 *	- 'poc'
	 *
	 * Currently, the system supports the follow filter types:
	 *	- 'review'
	 *
	 * @param {string}	testType	The type of the test.
	 * @param {string}	testFilter	The filter to apply.
	 *
	 * @return Observable<TestResponse>	An array of test objects.
	 */
	public getTests(testType, testFilter): Observable<TestResponse> {
		let endpoint = "/judgement";

		let paramString = '';
		if (testFilter == "review") {
			paramString = this.getParamString({
				'type': 'markedonce',
				'excludeselfoperator': true,
				'token': localStorage.getItem("token")
			})
		} else if (testFilter == "contrasting") {
			paramString = this.getParamString({
				'type': 'contrasting',
				'excludeselfoperator': true,
				'token': localStorage.getItem("token")
			})
		} else if (testFilter == 'all') {
			paramString = this.getParamString({
				'token': localStorage.getItem("token")
			})
		}

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<TestResponse>(url);
	}

	/***********************************
	 *         POC test review         *
	 ***********************************/

	/**
	 * Translate the POC test details' images into actual URLs.
	 * It is assumed that the response is ok.
	 *
	 * @param {POCTestDetailsResponse}	response	The POC test details response to edit.
	 *
	 * @return {POCTestDetailsResponse}	An updated POC test details response with URLs for images.
	 */
	public constructPOCTestImageURLs(response: POCTestDetailsResponse): POCTestDetailsResponse {
		let modified_response: POCTestDetailsResponse = Object.assign({}, response);
		let paramString = this.getParamString({
			'token': localStorage.getItem("token")
		})

		modified_response.poctPhoto1 = `${this.apiURL}${response.poctPhoto1}?${paramString}`;
		modified_response.poctPhoto2 = `${this.apiURL}${response.poctPhoto2}?${paramString}`;
		return modified_response;
	}

	public constructImageURL(responseImageURL: string): string {
		let paramString = this.getParamString({
			'token': localStorage.getItem("token")
		})
		return `${this.apiURL}${responseImageURL}?${paramString}`;
	}

	/**
	 * Get the POC test details for the candidate having the given ID.
	 *
	 * @param {string}	candidateID	The ID of the candidate whose POC test images will be shown.
	 *
	 * @return {Observable<POCTestDetailsResponse>}	An object with the details about the candidate's POC test.
	 */
	public getPOCTestDetails(candidateID: string): Observable<POCTestDetailsResponse> {
		let endpoint = "/poct";
		let paramString = this.getParamString({
			'candidateID': candidateID,
			'token': localStorage.getItem("token")
		})

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<POCTestDetailsResponse>(url);
	}

	/**
	 * Get all the judgements of a single candidate.
	 *
	 * @param {number}	candidateID	The ID of the candidate whose test results will be retrieved.
	 *
	 * @return Observable<TestResponse>	An array containing the candidate's test results.
	 */
	public getCandidateJudgements(candidateID): Observable<TestResponse> {
		let endpoint = "/judgement";

		let paramString = this.getParamString({
			'candidateID': candidateID,
			'excludeselfoperator': false,
			'token': localStorage.getItem("token")
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<TestResponse>(url);
	}

	/**
	 * Add a judgement to the candidate's POC test.
	 *
	 * @param {string}	candidateID	The candidate whose judgement will be saved.
	 * @param {string}	judgement	The POC test judgement.
	 * @param {boolean}		edit	A boolean indicating whether to update (PUT) an existing judgement or create (POST) a new one.
	 *
	 * @return {Observable<ApiResponse>}	The API's response.
	 */
	public saveJudgement(candidateID: string, judgement: string, edit: boolean): Observable<ApiResponse> {
		let endpoint = "/judgement";

		const postBody = new FormData();
		postBody.append("candidateID", candidateID);
		postBody.append("operatorID", localStorage.getItem("operatorID"));
		postBody.append("poctRes", judgement);
		postBody.append("token", localStorage.getItem("token"));

		let url = `${this.apiURL}${endpoint}`;
		if (edit) {
			return this.httpClient.put<ApiResponse>(url, postBody);
		} else {
			return this.httpClient.post<ApiResponse>(url, postBody);
		}
	}

	/***********************************
	 *      Blood test functions       *
	 ***********************************/

	/**
	 * Check whether the candidate having the given ID can perform a blood test.
	 *
	 * @param {string}	candidateID	The candidate for whom the check will be made.
	 * @param {string}	testType	The type of test that will be checked for the candidate.
	 *								Can be one of: 'poct', 'casefinding', 'bloodtests', 'biopsy', 'mucosaldeposits'.
	 *
	 * @return {boolean}	A boolean indicating whether the candidate can follow the pathway.
	 */
	public canFollowPathway(candidateID, testType) {
		let endpoint = "/checkpathway";

		let paramString = this.getParamString({
			'candidateID': candidateID,
			'step': testType,
			'token': localStorage.getItem("token")
		});

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<CandidatePathwayResponse>(url);
	}

	/**
	 * Get the list of locations.
	 * If a type of location is given, it is used to filter results.
	 *
	 * @param {string|null}testing_	country			The country whose locations to retrieve.
	 *										The country can be 'MT' or 'IT'.
	 *										If it is not given, locations from all countries are retrieved.
	 * @param {string|null}	locationType	The type of locations to retrieve.
	 *										The type can be 'hospital' or 'school'.
	 *										If it is not given, all locations are retrieved.
	 *
	 * @return {Observable<LocationsResponse>}	The API's response.
	 */
	public getLocations(testingCountry: string = null, locationType: string = null, locationID: string = null): Observable<LocationsResponse> {
		let endpoint = "/locations";

		let parameters = {
			'token': localStorage.getItem("token")
		}

		if (locationType != null) {
			locationType = locationType.toLowerCase() == 'hospital' ? 'h' : 's';
			parameters['locType'] = locationType;
		}

		if (testingCountry != null) {
			parameters['locState'] = testingCountry;
		}

		if (locationID != null) {
			parameters['locationID'] = locationID;
		}

		let paramString = this.getParamString(parameters);

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<LocationsResponse>(url);
	}

	/**
	 * Get the list of devices.
	 *
	 * @param {string}	testType			The test type to get relevant devices.
	 *
	 * @return {Observable<DevicesResponse>}	The API's response.
	 */
	public getDevices(testType: string): Observable<DevicesResponse> {
		let endpoint = "/devices" + testType;

		let parameters = {
			'token': localStorage.getItem("token")
		}

		let paramString = this.getParamString(parameters);

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<DevicesResponse>(url);
	}

	/**
	 * Submit the blood test.
	 *
	 * @param {{ [key: string]: any }}	result		The form input.
	 * @param {string}					testType	The test type to get relevant devices.
	 *
	 * @return {Observable<ApiResponse>}		The API's response.
	 */
	public bloodTest(result, testType: string): Observable<ApiResponse> {
		let endpoint = `/blood${testType}`;

		const postBody = new FormData();
		postBody.append("candidateID", result.candidateID);
		postBody.append("operatorID", localStorage.getItem('operatorID'));
		postBody.append("dateexam", `${result.dateOfTest.getFullYear()}-${String(result.dateOfTest.getMonth() + 1).padStart(2, '0')}-${String(result.dateOfTest.getDate()).padStart(2, '0')}`);
		postBody.append("devicesID", result.testDevice);
		postBody.append("token", localStorage.getItem("token"));

		/*
		 * Load other fields depending on the test type.
		 */
		const dependent_fields = {
			'igatot': ['iga_tot'],
			'ema': ['emaEval', 'magnification'],
			'ttgiga': ['ttg_iga'],
			'ttgigg': ['ttg_igg'],
			'dpgigg': ['dpg_igg'],
			'dpgiga': ['dpg_iga'],
			'aac': ['aac'],
		};
		

		/*
		 * If this is a TTG-IGA test and the value is greater than 100, set a very high value.
		 */
		if (result.ttg_iga_val == 'more-100') {
			result.ttg_iga = 1000;
		}

		/*
		 * Depending on the test type, add the fields.
		 */
		dependent_fields[testType].forEach((field) => {
			postBody.append(field, result[field]);
		});

		/*
		 * If this is an EMA, upload the photo separately.
		 */
		if (testType.toLowerCase() == 'ema') {
			postBody.append('emaPhoto', result.emaPhoto, result.emaPhoto.name)
		}

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	/**
	 * Get the blood test of a candidate.
	 *
	 * @param {string}	testType	The test type to get.
	 * @param {string}	candidateID	The ID of the candidate whose blood tests will be retrieved.
	 *
	 * @return {Observable<BloodTestResponse>}		The API's response.
	 */
	public getBloodTest(testType: string, candidateID: string): Observable<BloodTestResponse> {
		let endpoint = `/blood${testType}`;
		let parameters = {
			'candidateID': candidateID,
			'token': localStorage.getItem("token"),
		}

		let paramString = this.getParamString(parameters);

		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<BloodTestResponse>(url);
	}

	/**
	 * Submit the Endoscopy test.
	 *
	 * @param {{ [key: string]: any }}	result		The form input.
	 *
	 * @return {Observable<ApiResponse>}		The API's response.
	 */
	public endoscopyTest(result): Observable<ApiResponse> {
		let endpoint = null;

		const postBody = new FormData();
		postBody.append("candidateID", result.candidateID);
		postBody.append("operatorID", localStorage.getItem('operatorID'));
		postBody.append("dateexam", `${result.testDate.getFullYear()}-${String(result.testDate.getMonth() + 1).padStart(2, '0')}-${String(result.testDate.getDate()).padStart(2, '0')}`);
		postBody.append("locationID", result.testLocation);
		postBody.append("token", localStorage.getItem("token"));

		if (result.type == 'biopsy') {
			endpoint = '/biopsy';
			postBody.append("marsh", result.biopsy_eval);
			postBody.append("samplenr", result.biopsy_samplesnum);
		} else if (result.type == 'mucosal') {
			endpoint = '/mucosaldeposits';
			postBody.append("mucosalEval", result.mucosal_deposit_eval);
			postBody.append('mucosalPhoto', result.mucosal_photo, result.mucosal_photo.name);
		} else {
			// throw error -- how do we handle this?
			throw throwError(new Error(`unknown endoscopy test type: ${result.type}`));
		}

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	public getBiopsy(candidateID: string): Observable<ApiResponse> {
		let endpoint = `/biopsy`;
		let parameters = {
			'candidateID': candidateID,
			'token': localStorage.getItem("token"),
		}
		let paramString = this.getParamString(parameters);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}

	public getMucosalDeposits(candidateID: string): Observable<ApiResponse> {
		let endpoint = `/mucosaldeposits`;
		let parameters = {
			'candidateID': candidateID,
			'token': localStorage.getItem("token"),
		}
		let paramString = this.getParamString(parameters);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<ApiResponse>(url);
	}

	/**
	 * Search for candidates.
	 *
	 * @param {{ [key: string]: any }}	result				The form input.
	 * @param {{ [key: string]: any }}	blood		 			The blood form input.
	 * @param {{ [key: string]: any }}	endoscopy		  The endoscopy form input.
	 * @param {{ [key: string]: any }}	questionnaire	The questionnaire form input.
	 *
	 * @return {Observable<SearchResponse>}		The API's response.
	 */
	public search(result, blood, endoscopy, questionnaire): Observable<SearchResponse> {
		let endpoint = "/search";

		/*
		 * Format the dates.
		 */
		if (result.dateFrom) {
			let date_components = result.dateFrom.split('/');
			result.dateFrom = `${date_components[2]}-${date_components[1]}-${date_components[0]}`;
		}
		if (result.dateTo) {
			let date_components = result.dateTo.split('/');
			result.dateTo = `${date_components[2]}-${date_components[1]}-${date_components[0]}`;
		}

		const postBody = new FormData();
		postBody.append("token", localStorage.getItem("token"));

		/*
		 * Deal with the normal fields that are not part of modals
		 */
		Object.keys(result).forEach((key) => {
			if (result[key] == 'all') {
				result[key] = '';
			}

			if (result[key]) {
				postBody.append(key, result[key]);
			}
		});

		/*
		 * Go through the questionnaire fields
		 */
		Object.keys(questionnaire).forEach((key) => {
			if (questionnaire[key] == 'all') {
				questionnaire[key] = '';
			}

			if (questionnaire[key]) {
				postBody.append(key, questionnaire[key]);
			}
		});

		/*
		 * Go through the endoscopy test fields.
		 * First merge the biopsy evaluations, and then add the necessary values to the request.
		 */
		let biopsies = [ '0', '1', '2', '3a', '3b', '3c' ];
		endoscopy['marsh'] = [];
		biopsies.forEach((marsh) => {
			if (endoscopy['biopsyEvaluation' + marsh]) {
				endoscopy['marsh'].push(marsh);
			}

			delete endoscopy['biopsyEvaluation' + marsh];
		});
		endoscopy['marsh'] = endoscopy['marsh'].join(',');

		Object.keys(endoscopy).forEach((key) => {
			if (endoscopy[key] == 'all') {
				endoscopy[key] = '';
			}

			if (endoscopy[key]) {
				postBody.append(key, endoscopy[key]);
			}
		});

		/*
		 * Go through the blood test fields
		 */
		Object.keys(blood).forEach((key) => {
			if (blood[key] == 'all') {
				blood[key] = '';
			}

			if (blood[key]) {
				postBody.append(key, blood[key]);
			}
		});

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<SearchResponse>(url, postBody);
	}

	/**
	 * Load the latest statistics from the backend.
	 *
	 * @param {string}	country	The country from where to load the statistics.
	 * @param {string}	project	The project from where to load the statistics.
	 *
	 * @return {Observable<StatisticsResponse>}		The API's response.
	 */
	public getStatistics(country: string = null, project: string = null): Observable<StatisticsResponse> {
		let endpoint = `/statistics`;
		let parameters = { 'token': localStorage.getItem("token") }

		if (country != '' && country != null) parameters['country'] = country
		if (project != '' && project != null) parameters['project'] = project

		let paramString = this.getParamString(parameters);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<StatisticsResponse>(url);
	}

	public markAsCeliac(candidateID: string): Observable<ApiResponse> {
		const endpoint = "/finalstateceliac";
		const postBody = new FormData();
		postBody.append("token", localStorage.getItem("token"));
		postBody.append("candidateID", candidateID);

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	public markAsNonCeliac(candidateID: string): Observable<ApiResponse> {
		const endpoint = "/finalstatenonceliac";
		const postBody = new FormData();
		postBody.append("token", localStorage.getItem("token"));
		postBody.append("candidateID", candidateID);

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	public markAsRefusedFurtherTesting(candidateID: string): Observable<ApiResponse> {
		const endpoint = "/finalstaterefusal";
		const postBody = new FormData();
		postBody.append("token", localStorage.getItem("token"));
		postBody.append("candidateID", candidateID);

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	public proceedToNextTests(candidateID: string): Observable<ApiResponse> {
		const endpoint = "/proceedtonextstep";
		const postBody = new FormData();
		postBody.append("token", localStorage.getItem("token"));
		postBody.append("candidateID", candidateID);

		let url = `${this.apiURL}${endpoint}`;
		return this.httpClient.post<ApiResponse>(url, postBody);
	}

	public checkFinalDiagnosis(candidateID: string): Observable<CheckDiagnosisResponse> {
		const endpoint = "/checkdiagnosis";
		let parameters = {
			'token': localStorage.getItem("token"),
			'candidateID': candidateID
		}
		let paramString = this.getParamString(parameters);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<CheckDiagnosisResponse>(url);
	}

	public getCandidateStatus(candidateID: string): Observable<CandidateStatusResponse> {
		const endpoint = "/candidateStatus";
		let parameters = {
			'token': localStorage.getItem("token"),
			'candidateID': candidateID
		}
		let paramString = this.getParamString(parameters);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<CandidateStatusResponse>(url);
	}



	/**
 	 * @deprecated The method should not be used
 	 */
	public getStep(candidateID: string): Observable<CandidateStepResponse> {
		const endpoint = "/currentstep";
		let parameters = {
			'token': localStorage.getItem("token"),
			'candidateID': candidateID
		}
		let paramString = this.getParamString(parameters);
		let url = `${this.apiURL}${endpoint}?${paramString}`;
		return this.httpClient.get<CandidateStepResponse>(url);
	}

	/***********************************
	 *      Supporting functions       *
	 ***********************************/

	/**
	* Craft the parameter string from the given key-value pair.
	* This method is used only for GET requests.
	*
	* @param {{ [key: string]: any }}	paramsObject	The key-value pairs of parameters.
	*
	* @return {string}	The parameter string without a slash in the beginning.
	*/
	private getParamString(paramsObject: { [key: string]: any }) {
		let params = Object.keys(paramsObject).reduce((temp, element) => {
			return `${temp}&${element}=${paramsObject[element]}`;
		}, "")
		params = params.substring(1); // skip the first '&'.
		return params;
	}

}
