export class Questionnaire {

  public static readonly ITALIAN_QUESTIONS: { id: number, paramName: string, questionText: string, values: string[], options: string[], defaultAns: string }[] = [
    { id: 1, paramName: 'questTired', questionText: 'Debolezza/affaticamento', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 2, paramName: 'questImmunodeficiency', questionText: 'Deficit di IgA', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 3, paramName: 'questVomiting', questionText: 'Vomiting (more than 1 episode per month in last 3 months)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 4, paramName: 'questLiver', questionText: 'Iper transaminasemia isolate o persistente (livelli di ALT-AST doppi rispetto al range normale per almeno tre mesi)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 5, paramName: 'questDiabetes', questionText: 'Diabete tipo I insulino-dipendente', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 6, paramName: 'questAnaemia', questionText: 'Anemia', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 7, paramName: 'questRheumart', questionText: 'Artrite reumatoide', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 8, paramName: 'questRenal', questionText: 'Nefropatia a IgA', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 9, paramName: 'questEpilepsy', questionText: 'Epilessie resistenti a trattamenti farmacologici, o epilessie con calcificazioni intracraniche', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 10, paramName: 'questDentaldecay', questionText: 'Alterazioni dello smalto dentale', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 11, paramName: 'questMoodchanges', questionText: 'Depressione (in trattamento con farmaci)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 12, paramName: 'questLoosestools', questionText: 'Diarrea cronica e/o malassorbimento', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 13, paramName: 'questAbdpain', questionText: 'Dolori addominali ricorrenti (intestino o colon irritabile)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 14, paramName: 'questThyroid', questionText: 'Problemi di tiroide con anticorpi positivi', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 15, paramName: 'questAbdistention', questionText: 'Distensione addominale, meteorismo, flatulenza (intestino o colon irritabile)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 16, paramName: 'questIrregularbowel', questionText: 'Alvo irregolare (intestino o colon irritabile)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 17, paramName: 'questAlopecia', questionText: 'Alopecia', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 18, paramName: 'questVitiligo', questionText: 'Vitiligine', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 19, paramName: 'questDownturnerwilliams', questionText: 'Sindrome di Down o di Turner', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 20, paramName: 'questRecmouthulcers', questionText: 'Stomatiti aftose ricorrenti (più di quattro episodi per anno)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 21, paramName: 'questBalance', questionText: 'Atassia', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 22, paramName: 'questWeightloss', questionText: 'Perdita di peso', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 23, paramName: 'questGrowthprobl', questionText: 'Bassa statura', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 24, paramName: 'questWeakbones', questionText: 'Osteopenia (Z score < 2S.D.)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 25, paramName: 'questConstipation', questionText: 'Stitichezza', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 26, paramName: 'questJointpain', questionText: 'Dolori cronici o frequenti alle giunture (almeno sei volte all’anno)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 27, paramName: 'questLymphoma', questionText: 'Linfoma intestinale non-Hodgkin', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 28, paramName: 'questInfertility', questionText: 'Infertilità o aborti multipli', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 29, paramName: 'questOtherautoimmune', questionText: 'Altre malattie autoimmuni (come lupus eritematoso sistemico, ecc.) con diagnosi confermata da ospedali regionali di II o III livello', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' },
    { id: 30, paramName: 'questDermatitisherp', questionText: 'Dermatite erpetiforme (anche se solo sospetta)', values: ['Y', 'N', 'U'], options: ['Si', 'No', 'Non so'], defaultAns: 'U' }
  ];

  public static readonly MALTESE_QUESTIONS_KIDS: { id: number, paramName: string, questionText: string, values: string[], options: string[], defaultAns: string }[] = [
    { id: 1, paramName: 'questTired', questionText: 'Persistently tired/weak/low energy', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 2, paramName: 'questImmunodeficiency', questionText: 'Immunodeficiency', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 3, paramName: 'questVomiting', questionText: 'Vomiting (more than 1 episode per month in last 3 months)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 4, paramName: 'questLiver', questionText: 'Liver problems', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 5, paramName: 'questDiabetes', questionText: 'Diabetes (type 1)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 6, paramName: 'questAnaemia', questionText: 'Anaemia (pallor, low blood level)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 7, paramName: 'questRheumart', questionText: 'Rheumatoid Arthritis', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 8, paramName: 'questRenal', questionText: 'Renal problems', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 9, paramName: 'questEpilepsy', questionText: 'Epilepsy', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 10, paramName: 'questDentaldecay', questionText: 'Severe dental decay', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 11, paramName: 'questMoodchanges', questionText: 'Mood changes', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 12, paramName: 'questLoosestools', questionText: 'Persistent loose stools', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 13, paramName: 'questAbdpain', questionText: 'Repeatedly complains of abdominal pain', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 14, paramName: 'questThyroid', questionText: 'Thyroid problems', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 15, paramName: 'questAbdistention', questionText: 'Abdominal distention / bloating, flatulence', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 16, paramName: 'questIrregularbowel', questionText: 'Irregular bowel habits', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 17, paramName: 'questAlopecia', questionText: 'Alopecia (hair loss)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 18, paramName: 'questVitiligo', questionText: 'Vitiligo (white skin patches)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 19, paramName: 'questDownturnerwilliams', questionText: 'Down\'s, Williams or Turner\'s syndrome', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 20, paramName: 'questRecmouthulcers', questionText: 'Recurrent Mouth Ulcers', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 21, paramName: 'questBalance', questionText: 'Difficulty with balance/walking', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 22, paramName: 'questWeightloss', questionText: 'Poor weight gain, anorexia, weight loss', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 23, paramName: 'questGrowthprobl', questionText: 'Short stature / growth failure', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 24, paramName: 'questWeakbones', questionText: 'Weak bones', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 25, paramName: 'questConstipation', questionText: 'Constipation', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' }
  ];

  public static readonly MALTESE_QUESTIONS_ADULTS: { id: number, paramName: string, questionText: string, values: string[], options: string[], defaultAns: string }[] = [
    { id: 1, paramName: 'questTired', questionText: 'Weakness/fatigue', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 2, paramName: 'questImmunodeficiency', questionText: 'Total IgA deficiency', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 3, paramName: 'questVomiting', questionText: 'Vomiting (more than 1 episode per month in last 3 months)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 4, paramName: 'questLiver', questionText: 'Isolated and persistent hyper-transaminasemia (ALT-AST level two times the normal range for at least 3 months)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 5, paramName: 'questDiabetes', questionText: 'Insulin-dependent type I diabetes', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 6, paramName: 'questAnaemia', questionText: 'Anaemia', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 7, paramName: 'questRheumart', questionText: 'Rheumatoid Arthritis', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 8, paramName: 'questRenal', questionText: 'IgA nephropathy', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 9, paramName: 'questEpilepsy', questionText: 'Epilepsies resistant to pharmacological treatment or epilepsies with intracranic calcification', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 10, paramName: 'questDentaldecay', questionText: 'Teeth enamel defects', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 11, paramName: 'questMoodchanges', questionText: 'Depression (treated with drugs)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 12, paramName: 'questLoosestools', questionText: 'Chronic diarrhoea and/or malabsorption', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 13, paramName: 'questAbdpain', questionText: 'Repeatedly complains of abdominal pain (IBS)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 14, paramName: 'questThyroid', questionText: 'Thyroid disorders with positive antibodies', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 15, paramName: 'questAbdistention', questionText: 'Abdominal distention / bloating, flatulence (IBS)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 16, paramName: 'questIrregularbowel', questionText: 'Irregular bowel habits (IBS)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 17, paramName: 'questAlopecia', questionText: 'Alopecia', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 18, paramName: 'questVitiligo', questionText: 'Vitiligo', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 19, paramName: 'questDownturnerwilliams', questionText: 'Down syndrome and Turner syndrome', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 20, paramName: 'questRecmouthulcers', questionText: 'Recurrent aphtous stomatitis (more than four episodes/year)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 21, paramName: 'questBalance', questionText: 'Ataxia', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 22, paramName: 'questWeightloss', questionText: 'Weight loss', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 23, paramName: 'questGrowthprobl', questionText: 'Short stature', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 24, paramName: 'questWeakbones', questionText: 'Osteopenia (Z score < 2 S.D.)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 25, paramName: 'questConstipation', questionText: 'Constipation', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 26, paramName: 'questJointpain', questionText: 'Chronic or recurrent joint pain (at least six times/year)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 27, paramName: 'questLymphoma', questionText: 'Non-Hodgkin intestinal lymphoma', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 28, paramName: 'questInfertility', questionText: 'Infertility and/or multiple miscarriage', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 29, paramName: 'questOtherautoimmune', questionText: 'Other autoimmune disorders (such as systemic erythematosus lupus, etc.) with confirmed diagnosis at II or III level regional hospital', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' },
    { id: 30, paramName: 'questDermatitisherp', questionText: 'Dermatitis herpetiformis (even if only suspected)', values: ['Y', 'N', 'U'], options: ['Yes', 'No', 'Unknown'], defaultAns: 'U' }
  ];

}
