
declare var $: any;

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';

import { Candidate } from "../classes/candidate";
import { FormComponent } from '../form/form.component';

@Component({
	selector: 'app-to-review',
	templateUrl: './to-review.component.html',
	styleUrls: ['./to-review.component.css']
})
export class ToReviewComponent extends FormComponent implements OnInit {

	/**
	 * The API service is used to fetch
	 *
	 * @param {ActivatedRoute}		route			The currently-loaded route.
	 * @param {ApiService}			apiService		The service that connects to the API.
	 * @param {AuthService}			authService		The service that checks whether a user can access certain pages.
	 * @param {FormBuilder}			formBuilder		The form builder service.
	 * @param {Router}				router			The router service.
	 */
	constructor(
		private route: ActivatedRoute,
		apiService: ApiService,
		public authService: AuthService,
		private formBuilder: FormBuilder,
		private router: Router) {
		super(apiService);
	}

	/**
	 * When the component loads, get a list of tests that need reviewing.
	 */
	ngOnInit() {
		this.form = this.createFormGroup(this.formBuilder);
		this.route.params.subscribe(params => {
			if (params.show)
				this.form.controls['show'].setValue(params.show);
			this.loadTests();
		});
	}

	/**
	 * Create a form group for the POC test.
	 *
	 * @param {FormBuilder}	formBuilder	The injected form builder.
	 *
	 * @return {FormGroup} The form group for the model.
	 */
	createFormGroup(formBuilder: FormBuilder) {
		return formBuilder.group({ show: [ 'all' ] });
	}

	/**
	 * Load the tests to display in the table.
	 * The tests are loaded directly into the `tests` variable.
	 */
	loadTests() {
		let testType = "poc";
		let testFilter = this.form.get('show').value;

		let table = $("#datatable").DataTable();
		table.clear().draw();

		if (testType != 'poc') {
			return;
		}

		let apiTests = [];
		this.apiService.getTests(testType, testFilter).subscribe((response) => {
			if (response.result == "ok" && this.form.get('show').value == testFilter) {

				let table = $("#datatable").DataTable();
				table.clear().draw();

				let i = 0;
				let rows = [ ];
				$.each(response.results, (candidateID, element) => {
					i++;
					let poc = element[0]; // NOTE: Each POC test is wrapped in an array.
					let path = this.router.createUrlTree(['review', candidateID]).toString();
					let url = window.location.href.replace(this.router.url, path);

					if (testFilter != 'all') {
						rows.push([
							i, candidateID,
							`<a href='${url}'"
								class="btn btn-primary"
								i18n="@@to-review-review" >
								<span class="to-review-icon"></span>`
						]);
					} else {
						/*
						 * Calculate the age.
						 */
						let age = 0;
						if (element.candidateBirthdate) {
							const date = element.candidateBirthdate.split('-');
							let dateOfBirth = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2])); // months start from index 0
							age = Candidate.calculateAge(dateOfBirth);
						}

						rows.push([
							i, candidateID,
							element.candidateTown.town
											.replace(/[A-Z]/g, ' $&')
											.trim(),
							element.candidateEthnicity,
							Math.round(age * 100)/100.,
							element.candidateGender
						]);
					}
				});
				table.rows.add(rows).draw();
			}
		});

		/*
		 * If too many wrappers exist, remove them.
		 */
		let wrappers = $('.dataTables_wrapper.no-footer');
		if (wrappers.length > 1) {
			wrappers.remove();
		}
	}

}
